import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as Sentry from "@sentry/angular";

import { environment } from '../../../../environments/environment';
import { ContentService } from '../content/content.service';
import { AppidService } from '../appid/appid.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient, private appid: AppidService) { }

    public isLoggedIn: boolean = false;
    private token: string;
    private role: number;
    private org: number;

    login(username: string, password: string, nav): Observable<any> {
        return new Observable((observer) => {
            this.http.post(environment.urls.core + `/auth/login?app_id=${this.appid.appID}&nav_id=` + nav, {
                email: username,
                password: password
            }).subscribe(
                (response) => {
                    this.token = response['token'];
                    this.role = response['role'];
                    this.org = response['org'];

                    localStorage.setItem("role", this.role.toString());
                    // org is optional
                    localStorage.setItem("org", this.org?.toString());

                    localStorage.setItem("token", this.token);
                    this.isLoggedIn = true;
                    observer.next(response);

                    //set sentry scopes
                    Sentry.configureScope((scope) => {
                        scope.setUser({
                            email: username,
                            username: username,
                            role: this.role,
                        });
                    });
                },
                (error) => {
                    observer.error(error);
                }
            )
        });
    }

    registerOrg(orgName: string, role: string): Observable<any> {
        return new Observable((observer) => {
            this.http.post(environment.urls.core + '/auth/registerorg', {
                OrgName: orgName,
                RoleID: parseInt(role)
            }).subscribe(
                (response) => {
                    observer.next(response);
                },
                (error) => {
                    observer.error(error);
                }
            )
        });
    }

    getRole(): number {
        if (this.role == null) this.role = parseInt(localStorage.getItem("role"));
        return this.role;
    }

    getOrg(): number {
        if (this.org == null) this.org = parseInt(localStorage.getItem("org"));
        return this.org;
    }

    getToken(): string {
        if (this.token == null) this.token = localStorage.getItem("token");
        return this.token;
    }

    saveToken(token) {
        localStorage.setItem("token", token);
        this.token = token;
    }

    setOrgAndRole(org: number, role: number): void {
        this.org = org;
        this.role = role;
        localStorage.setItem("org", this.org.toString());
        localStorage.setItem("role", this.role.toString());
    }

    logout(): void {
        this.token = '';
        this.role = null;
        this.org = null;
        this.isLoggedIn = false;

        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("org");

        sessionStorage.removeItem("isInWorkflow");

        Sentry.configureScope((scope) => {
            scope.clear();
        });
    }

    changeOrg(orgID): Observable<any> {
        return new Observable((observer) => {
            let httpParams = new HttpParams()
                .set('app_id', this.appid.appID)
                .set('org_id', this.getOrg())
                .set('newOrgID', orgID);

            this.http.get(environment.urls.core + '/auth/changeorg', {
                params: httpParams,
                headers: {
                    'authorization': this.getToken()
                }
            }).subscribe(
                (data: any) => {
                    this.setOrgAndRole(data.newOrg, data.newRole);
                    this.saveToken(data.token);
                    observer.next(data);
                },
                (error: any) => {
                    observer.error(error);
                    console.error(error);
                }
            );
        });
    }
}
