import { HttpClient } from '@angular/common/http';
import { Lexer } from '@angular/compiler';
import { Component, Injectable, Input, OnInit } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { AppidService } from 'src/app/base/services/appid/appid.service';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { navigate } from '../../../base/helpers';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.css']
})

@Injectable({
    providedIn: 'root'
})

export class SidenavComponent implements OnInit {
    data: any = null;

    @Input("isRegMode") isRegMode: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public app: AppComponent,
        public AppId: AppidService) { }


    ngOnInit(): void {

        this.data = [];

        this.activatedRoute.url.subscribe(url => {
            this.data = [];

            //combine url array into string
            let urlString = url[0].path; //basepath

            //filter this.router.config to get all routes that begin with the urlString
            let filteredRoutes = this.router.config.filter(x => x.path.split("/")[0] == urlString && x.path.split("/").length > 1);

            this.data.push(...this.buildNavArray(filteredRoutes, "", 0));

            //set all ancestor items to expanded
            //find the currently selected item in data
            let index = 2;
            let parent: any = this.data.find(x => x.link.split("/")[index] == this.router.url.split("/")[index]);
            while (parent != null) {
                index++;
                parent.collapsed = false;
                parent = parent.children.find(y => y.originalLink.split("/")[index] == this.router.url.split("/")[index]);
            }
        });

        //recursively sort this.data by sortOrder
        this.data.sort(this.asIsOrder);

        this.data.forEach(element => {
            if (element.children != null) {
                element.children.sort(this.asIsOrder);
                element.children.forEach(child => {
                    if (child.children != null) {
                        child.children.sort(this.asIsOrder);
                    }
                })
            }
        });

        // With the below line you can now reload the same component from the same route
          
    }

    buildNavArray(routes: Route[], basepath: string, level: number): any {
        let newItems = [];

        let thisLevelRoutes = routes.filter(x => {
            let validPath = true;
            if (basepath != "") {
                validPath = x.path.indexOf(basepath) == 0;
            }
            return x.path.split("/").length == level + 2 && validPath
        });

        thisLevelRoutes.forEach(item => {
            let itemChildren = this.buildNavArray(routes, item.path + "/", level + 1);
            itemChildren.sort(this.asIsOrder);

            let link = "/" + item.path;
            let originalLink = link;
            if (!item.data.hasPage && itemChildren.length > 0 && itemChildren[0].hasPage) {
                link = itemChildren[0].link;
            }

            let newItem = {
                label: item.data.label,
                link: link,
                children: itemChildren,
                hasPage: item.data.hasPage,
                collapsed: true,
                originalLink: originalLink,
                sortOrder: item.data.sortOrder
            };

            newItems.push(newItem);
        });

        return newItems;
    }

    hasChildren(data: any): boolean {
        return (data.children.length > 0);
    }

    expandOrCollapse(item: any): void {
        item.collapsed = !item.collapsed;
    }

    /**
     * Handles navigation to the specified route using a common navigation function.
     *
     * @param link - The target route to navigate to.
     * @returns void
    */
    navigateHandler(link: string): void {
        navigate(this.router, link);
    }

    asIsOrder(a, b) {
        return Number.parseInt(a.sortOrder) > Number.parseInt(b.sortOrder) ? 1 : -1;
    }

    //determine if this top nav is active
    isActive(link): boolean {
        //check if this.router.url minus any http parameters matches the link
        let url = this.router.url.split("?")[0];
        return url == link;
    }


}
