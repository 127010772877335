import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from '../../interfaces/field';

@Component({
  selector: 'app-dropdown-base',
  template: ``,
  styles: []
})
export class DropDownBaseComponent {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup;

    constructor() { }

    originalOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
        if (a.value.order && b.value.order) return a.value.order > b.value.order ? 1 : (b.value.order > a.value.order ? -1 : 0);
        else return 1;
    }

    getIn() {
        return this.field.instructions;
    }


}

