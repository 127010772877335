import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';

@Component({
    selector: '[app-easysuite-password]',
    templateUrl: './easysuite-password.component.html',
    styleUrls: ['./easysuite-password.component.css']
})
export class EasysuitePasswordComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup

    constructor() { }

    ngOnInit(): void {
    }

}
