import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from '../../interfaces/field';

@Component({
  selector: 'app-textarea-base',
  template: ``,
  styles: []
})
export class TextAreaBaseComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup;

    constructor() { }

    ngOnInit(): void {
    let format = this.field.format;
    //date, time, price, number, phone number, email
    }
}
