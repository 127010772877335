import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginComponent } from 'src/app/gigmonster/components/login/login.component';
import { SuperstructureComponent } from 'src/app/gigmonster/components/superstructure/superstructure.component';
import { OrgInfo } from 'src/app/base/interfaces/org-info';
import { SuperstructureEasysuiteComponent } from 'src/app/easysuite/components/easysuite-superstructure/easysuite-superstructure.component';
import { environment } from '../../../../environments/environment';
import { ContentService } from '../content/content.service';
import { AppidService } from '../appid/appid.service';
import { EasysuiteLoginComponent } from 'src/app/easysuite/components/easysuite-login/easysuite-login.component';

@Injectable({
    providedIn: 'root'
})

export class NavService {
    superstructureType: any = null;
    loginType: any = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        public content: ContentService,
        private appid: AppidService) {
        if (appid.appID == 434693) {
            this.superstructureType = SuperstructureComponent;
            this.loginType = LoginComponent;
        } else {
            this.superstructureType = SuperstructureEasysuiteComponent;
            this.loginType = EasysuiteLoginComponent;
        }
    }

    loadNavsByCache(): boolean {
        try {
            let navs = JSON.parse(localStorage.getItem("routes"));
            if (navs == null) return true;

            navs.forEach(nav => {
                if (nav.redirectTo == null) nav.component = this.superstructureType;
                if (nav.path == "auth/login") {
                    //auths are special
                    nav.component = this.loginType;
                }
            });

            this.router.config = navs;
            this.router.resetConfig(this.router.config);
            return true;
        } catch (error) {
            console.warn(error);
            return false;
        }
    }

    // this function is called when a user logins in so that the router has the correct routes for that role
    updateNavs(token: string, role: number): Observable<any> {
        // reset router to nothing
        this.router.resetConfig([]);
        return new Observable((observer) => {
            this.http.get(environment.urls.core + `/navs?app_id=${this.appid.appID}&role_id=` + role, {
               }).subscribe(
                (next) => {
                    if (!this.router.config.some(existingNav => existingNav.path === 'auth/login')) {

                        // this adds in the fake 15 page since that is not in the DB
                        // it also sets the default (if the usrl is not found in the router) to redirect to the fake 15 login page
                        // this is added in so that if the user leaves and comes back to the site, they are redirected to the login page correctly
                        // this will need to be updated for ezs, since it doesnt have a fake 15
                        // you will want to change the default path ** to be the url to the login page for the app you are in
                        this.router.config.unshift({ path: 'auth/login', component: LoginComponent });

                        // // if the route is not recognized (hack) default to login page
                        this.router.config.push({ path: '**', redirectTo: 'auth/login' });
                    }
                    // add the navs and their children to the router
                    next["navs"].forEach(nav => {
                        this.router.config.unshift(...this.recurseNavs(nav));
                    });

                    this.router.resetConfig(this.router.config);
                    localStorage.setItem("routes", JSON.stringify(this.router.config));
                    observer.next(next);
                },
                (error) => {
                    console.warn(error);
                    observer.error(error);
                }
            );
        });
    }

    getUnknownPersonNavs(): Observable<any> {
        return this.updateNavs(null, 0);
    }

    // this is used primarily on logout/ initial site visit, it removes all routes from the router and adds in the unauth routes
    clearNavs(): Observable<any> {
        console.log("clear navs has been called")
        return new Observable((observer) => {
            this.getUnknownPersonNavs().subscribe(
                (response) => {

                    const currentUrl = this.router.url;
                    //This is a Temporary Solution. 
                    //the below URLs needs to be redirected without the help of cached NAVs during
                    // situations like "Forgot Password" or "Register via invite"
                    const allowedUnauthUrls = [
                        '/venues/register',
                        '/venues/forgotpassword',
                        '/performers/register',
                        '/performers/forgotpassword'
                    ];

                    if (allowedUnauthUrls.some(url => currentUrl.includes(url))) {
                        this.router.navigateByUrl(currentUrl);
                    }else{
                        // Execute your code
                        this.router.resetConfig(this.router.config);
                        localStorage.setItem("routes", JSON.stringify(this.router.config));
                        observer.next(response);
                    }
                },
                (error) => {
                    console.error("Failed to update the navs:", error);
                    observer.error(error);
                }
            );
        });
    }


    getOrgName(orgID, authToken): Observable<OrgInfo> {
        let httpParams = new HttpParams()
            .set('app_id', this.appid.appID)
            .set('org_id', orgID)

        return this.http.get<OrgInfo>(environment.urls.core + '/navs/header', {
            params: httpParams,
            headers: {
                'authorization': authToken
            }
        });
    }

    private recurseNavs(parentNav: any, parentLabel = ""): Route[] {
        let routes: Route[] = [];
        let label = '';
        if (parentLabel !== "") label = parentLabel + "/";
        label = (label + parentNav["label"]).replace(/\s/g, "").toLowerCase();

        routes.push({ path: label, component: this.superstructureType, data: { nav: parentNav["id"], label: parentNav["label"], hasPage: parentNav["hasPage"], sortOrder: parentNav["sort_order"], navPage: parentNav["page"] } });

        parentNav["children"].forEach(childNav => {
            routes.push(...this.recurseNavs(childNav, label));
        });

        return routes;
    }
}
