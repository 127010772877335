import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { AppidService } from 'src/app/base/services/appid/appid.service';
import { WorkflowService } from 'src/app/base/services/workflow/workflow.service';
import { AppComponent } from 'src/app/app.component';

import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

// this is the "fake" 15 login page
export class LoginComponent implements OnInit {
    loginForm = this.fb.group({});

    selectedRole = 0;
    loginInProgress = false;

    navID = 0;

    get fields() {
        return this.loginForm.get('fields') as UntypedFormArray;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private snackBar: MatSnackBar,
        private router: Router,
        private auth: AuthService,
        private nav: NavService,
        private activatedRoute: ActivatedRoute,
        private workflow: WorkflowService,
        public app: AppComponent,
        public http: HttpClient) { }


    ngOnInit() {
        // log out the user if they end up on the login page
        this.auth.logout();
        this.nav.clearNavs().subscribe(
            (data) => {
                console.log("Navs cleared");
            },
        );

        this.loginForm.addControl("Username", this.fb.control(''));
        this.loginForm.addControl("Password", this.fb.control(''));

        this.activatedRoute.queryParams.subscribe(params => {
            //if ?role parameter is set to 97, make the venue login button active
            if (params['role'] == 97) this.navID = 374;
            else if (params['role'] == 98) this.navID = 370;

            if (params["msg"]) {
                this.snackBar.open(params["msg"], '', {

                });
            }
        });
    }

    onSubmit() {
        if (this.navID == 0) {
            //check the width of the screen
            this.openSnackBar('To login, you first choose a role.', '', 2000);
            return;
        }

        if (!this.loginInProgress && this.loginForm.valid) {
            this.loginInProgress = true;
            this.auth.login(this.loginForm.get('Username').value, this.loginForm.get('Password').value, this.navID).subscribe(
                async (data) => {
                    let roleID = data["role"];
                    let targetNav = data["nav"];
                    let targetPage = data["page"];
                    let targetMsg = data["msg"];

                    let isRegistrationRole = false;
                    //283 or 287 should also use workflowservice to get which page to go to
                    await this.http.get<boolean>(environment.urls.core + `/auth/isRegistrationRole?role_id=${roleID}`).subscribe(response => {
                        isRegistrationRole = response;
                        if (isRegistrationRole) {
                            let workflowData = this.workflow.initialLogin().toPromise();
                            targetNav = workflowData["nav"];
                        }
                    });

                    this.nav.updateNavs(data['token'], data['role']).subscribe(
                        (routeLoaded) => {
                            /**
                             * update 11/17/22: the logic here has been modified to support sending to people
                             * to a specific page, even with a submit message! This was a requirement to support
                             * non-required registration steps.
                             * 
                             * If a target page is specified, the nav cache is searched to see if the page is associated
                             * with a nav. If it is, we sent them to that nav. If it is not, we send them to the nav
                             * that was included in the request and attach the page as a ?page query parameter.
                             * 
                             * In both cases, if a submit message is specified, that is attached as another query parameter
                             * with the ?msg key, and that will be displayed on whatever page the user is sent to.
                             */

                            let url = null;
                            if (targetPage) {
                                url = this.router.config.find(route => {
                                    if (route.data && route.data.hasPage) {
                                        return route.data.navPage == targetPage;
                                    }
                                    return false;
                                });

                                if (url) url = url.path;

                                if (targetMsg && url) {
                                    url += "?msg=" + targetMsg;
                                }
                            }

                            if (!url) {
                                url = this.router.config.find(route => route.data.nav === targetNav).path;

                                if (targetPage) {
                                    url = url + "?page=" + targetPage;

                                    if (targetMsg) {
                                        url = url + "&msg=" + targetMsg;
                                    }
                                }
                            }

                            this.loginInProgress = false;
                            this.router.navigateByUrl(url);
                        },
                        (error) => { }
                    );
                },
                (error) => {
                    this.openSnackBar('Login failed: ' + error["error"] + '. Make sure you have selected the correct role.', '', 5000);
                    this.loginInProgress = false;
                }
            );
        }
    }

    switchNav(newlySelectedNav: number): void {
        this.navID = newlySelectedNav;
        this.selectedRole = newlySelectedNav == 374 ? 97 : 98;
    }

    /**
     * update 8/21, per GIG-T89, Cyril wants to go to the registration page
     * based upon the currently active button on the login page
     */
    getSignupLink(): any {
        return (this.selectedRole == 97 ? "/venues" : "/performers") + "/register"
    }

    promptSignupRole(): any {

        if (this.selectedRole == 0) return this.openSnackBar('To sign up, you first choose a role.', '', 5000);
    }

    promptForgotPasswordRole(): any {

        if (this.selectedRole == 0) return this.openSnackBar('To reset your password, you first choose a role.', '', 5000);
    
    }

    promptGetHelpRole(): any {

        if (this.selectedRole == 0) return this.openSnackBar('To get help, you first choose a role.', '', 5000);

    }

    getForgotPasswordLink(): string {
        if (this.selectedRole == 97) return "/venues/forgotpassword";
        else return "/performers/forgotpassword";
    }

    sendToSupport(): void {
        if (this.selectedRole == 97) {
            this.router.navigateByUrl("/venues/register?page=330");
        } else {
            this.router.navigateByUrl("/performers/register?page=330");
        }
    }


    /**
     * Takes the message, action, and duration and opens a snackbar with the given parameters.
     * 
     * @param message 
     * @param action 
     * @param duration 
     * 
     * @returns void
     */
    openSnackBar(message: string, action: string, duration: number) {

        //if the screen is wider than 768px, use the default snackbar
        if (window.innerWidth > 768) {
            this.snackBar.open(message, action, {
                duration,
            });
        } else {

            this.snackBar.open(message, action, {
                duration,
                verticalPosition: 'bottom',
                panelClass: 'mat-snack-bar-container-panel-class'
            });
        }
    }
}
