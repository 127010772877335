<!-- 
    this is some voodoo nonsense!

    this is a component that is used to create a top nav bar. it uses
    recursion to create the nav bar. the components downloads the latest
    nav data from the server and creates a rootItem template for each
    root level item, and then a subItem template for each sub level item, recursively.

    it uses native <ul> and <li> tags to do this, as well as angular templates

    for more information how this works see https://www.ozkary.com/2017/06/angular2-navigation-menu-with-recursive.html
-->

<ng-container *ngIf="(app.innerWidth >= 850)">
    <ng-template #rootItem let-item class="topnav">
        <li class="level0">
            <a (click)="navigateHandler(item.path)" [class.selected]="isActive(item.path)">{{item.data.label | uppercase}}</a>
        </li>
    </ng-template>

    <nav class="topnav">
        <ng-container *ngFor="let item of data">
            <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: item}"></ng-container>
        </ng-container>
        <li class="level0" *ngIf=!this.isRegMode>
            <a (click)="logout()">LOGOUT</a>
        </li>
    </nav>
</ng-container>

<!-- Button to trigger overlay menu -->
<ng-container *ngIf="(app.innerWidth < 850)" class="mobileNav">
    <div class="mobileNav" (click)="triggerMobileNav()">
        <span
            style="color: white; padding-top: 10px; padding-bottom: 10px; padding-left: 20px; font-size: medium;">Menu</span>
        <mat-icon style="color: white; padding-top: 10px; padding-bottom: 10px; padding-right: 20px;">menu</mat-icon>
    </div>
</ng-container>

<app-mobile-nav *ngIf="(mobileViewTrigger) && this.showMobileNav" (collapseMobileNav)="this.collapseMobileNav()">
</app-mobile-nav>