import { Component, OnInit } from '@angular/core';
import { TimePickerBaseComponent } from 'src/app/base/components/fields/timepicker-base.component';

@Component({
  selector: 'app-easysuite-timepicker',
  templateUrl: './easysuite-timepicker.component.html',
  styleUrls: ['./easysuite-timepicker.component.css']
})
export class TimepickerComponent extends TimePickerBaseComponent implements OnInit {

  constructor() {super()}

  ngOnInit(): void {
  }

}
