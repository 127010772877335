import { Component, Input, OnInit } from '@angular/core';
import { TextAreaBaseComponent } from 'src/app/base/components/fields/textarea-base.component';
import { SuperstructureBaseComponent } from 'src/app/base/components/superstructure-base.component';
import { TextareaComponent } from 'src/app/gigmonster/components/fields/textarea/textarea.component';
import { MatFormField } from '@angular/material/form-field';
import { TextFieldBaseComponent } from 'src/app/base/components/fields/textfield-base.component';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';

@Component({
    selector: '[app-easysuite-textarea]',
    templateUrl: './easysuite-textarea.component.html',
    styleUrls: ['./easysuite-textarea.component.css']
})
export class EasysuiteTextareaComponent extends TextAreaBaseComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup;
    constructor() { super() }

    ngOnInit(): void {
    }

}
