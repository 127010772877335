import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';
import { ContentService } from 'src/app/base/services/content/content.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup

    fileName: string;
    uploadedFile: string;

    constructor(private content: ContentService) { }

    ngOnInit(): void {
        if (this.field.value != null && this.field.value != "") {
            let envURL = environment.urls.core;
            this.uploadedFile = envURL + this.field.value;
        }
    }

    onFileSelected(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileName = "Selected: " + file.name;

            var reader = new FileReader();

            reader.onload = (e) => {
                this.formGroup.get(this.fieldKey).setValue({
                    fileName: file.name,
                    base64: reader.result,
                    fileType: file.type
                });
            };
            
            reader.readAsDataURL(file);
        }
    }
}
