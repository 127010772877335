import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { init } from '@sentry/angular';
import { getIntegrationsToSetup } from '@sentry/core';
import { Field } from 'src/app/base/interfaces/field';
import { TextfieldService } from 'src/app/base/services/textfield/textfield.service';

@Component({
    selector: 'app-textfield',
    templateUrl: './textfield.component.html',
    styleUrls: ['./textfield.component.css']
})
export class TextfieldComponent{
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup

    receivedData: any;

    constructor(private TextfieldService: TextfieldService) {
        
    }

    ngOnInit() {
        this.TextfieldService.getSharedData().subscribe(data => {
            this.receivedData = data;
            this.populateSlotPrice(this.receivedData);
        });
    }

    populateSlotPrice(data){
        console.log(data);
        //740 and 543 are max price and min price
        //using 734 here because it is the slot dropdown
        if(data.fieldId == '734'){
            //loop through the fields and find if 740 or 543 is present, if its present then set the value
            for (const controlKey in this.formGroup.controls) {
                if (this.formGroup.controls.hasOwnProperty(controlKey)) {
                  if (controlKey === '740') {
                    this.formGroup.controls[controlKey].setValue(data.price);
                  } else if (controlKey === '543') {
                    this.formGroup.controls[controlKey].setValue(data.price);
                  }
                }
              }
        }
    }

    getMask() {
        if (this.field.format) {
            if (this.field.format == "phone number") return "(000)-000-0000";
        }

        return "";
    }

    getIn() {
        return this.field.instructions;
    }
    
    getMaxLenght(){
        if(this.field.label){
            if(this.field.label == 'Account Number'){
                return 18;
            }

            else if(this.field.label == "Routing Number"){
                return 9;
            }
        }
        return 100
    }   
}
