import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxMaskModule } from "ngx-mask";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { EllipsisModule } from 'ngx-ellipsis';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    NgScrollbarModule,
    NgxSkeletonLoaderModule,
    NgxMaskModule,
    EllipsisModule,
    RouterModule
  ]
})
export class GlobalSharedModule { }
