import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form } from 'src/app/base/interfaces/form';
import { UntypedFormControl, UntypedFormGroup, FormsModule, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { InvalidPopupComponent } from '../invalid-popup/invalid-popup.component';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})

export class FormComponent implements OnInit {
    @Input() form: Form;
    @Input() showSubmitButton: boolean = true;
    @Input() parentSubmitButton: Observable<boolean> = null;
    @Input() pageID: Number = null;

    @Output() formSubmitted = new EventEmitter<Record<number, string>>();

    formGroup: UntypedFormGroup;

    constructor(
        private route: ActivatedRoute, private dialog: MatDialog) { }



    ngOnInit() {
        let endDate: any;
        if (this.parentSubmitButton != null) {
            this.parentSubmitButton.subscribe(x => {
                this.submitForm();
            })
        }

        this.formGroup = new UntypedFormGroup({}, {
            updateOn: 'change'
        });

        for (let key in this.form.fields) {
            let field = this.form.fields[key];

            let newControl = new UntypedFormControl();

            if (field.required) newControl.addValidators(Validators.required);
            if (field.value) newControl.setValue(field.value);

            this.formGroup.addControl(key, newControl);
        }

        // previously used when receiving data from GM website to org registration page
        // delete this code by 7/1/23 or later if no issues arise
        /*if (this.route.snapshot.data.registrationMode && this.pageID == 609) {
            this.route.queryParams.subscribe(urlParams => {
                this.formGroup.controls["1"].setValue(urlParams["emailAddress"]);
                this.formGroup.controls["3"].setValue(urlParams["phone"]);
                this.formGroup.controls["29"].setValue(urlParams["actName"]);
                this.formGroup.controls["29"].setValue(urlParams["organizationName"]);
                this.formGroup.controls["33"].setValue(urlParams["website"]);
            });
        }*/

        if (this.formGroup.controls['58'] != undefined) {
            this.formGroup.controls['58'].valueChanges.subscribe(x => this.promptEndDate())
        }

        if (this.formGroup.controls['58'] != undefined) {
            this.formGroup.controls['58'].valueChanges.subscribe(x => this.promptEndDate())
        }
    }

    submitForm() {
        if (!this.formGroup.valid) {
            let list = []
            for (let key in this.form.fields) {
                if (this.formGroup.get(key).invalid) {
                    this.formGroup.get(key).markAsTouched();
                }
                list.push(key)
            }

            if (this.formGroup.invalid) {
                const popupDialog = this.dialog.open(InvalidPopupComponent, {
                    data: {
                        message: "All highlighted fields are required in their proper format."
                    }
                });
            }
            return
        }

        //form is valid, submit it
        let fieldsToSubmit: Record<number, string> = {};
        for (let key in this.form.fields) {
            fieldsToSubmit[key] = this.formGroup.get(key).value;
        }

        this.formSubmitted.emit(fieldsToSubmit);
    }

    cancelForm() {
        //go to previous page
        window.history.back();
    }

    asIsOrder(a, b) {
        return a.value.fieldOrder > b.value.fieldOrder ? 1 : -1;
    }

    promptEndDate(): any {
        if (this.form.fields['58'] && this.form.fields['59']) {
            if (this.form.fields['58'] != undefined) {
                return this.formGroup.controls['59'].setValue(this.formGroup.controls['58'].getRawValue());
            }
        }
    }

    /*
    onStartChange(event: any, endDate) {
        console.log('change ', event.value);
        if (endDate) return;
        endDate = moment(event.value).endOf('month').toDate();
      }
      getINFO(endDate){
          if (this.form.fields['58'] && this.form.fields['59']){
              if(this.form.fields['59']){
                return endDate;
          } 
      }

    }
    */
    firstLabel(): void {
        for (let key in this.form.fields) {
            if (this.formGroup.get(key).markAsTouched) {
                let label = this.form.fields[key].label
            }
        }
    }

}
