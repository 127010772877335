<div class="containerImage">
    <div class="container">
        <!--Display at smaller resolutions-->
        <ng-container *ngIf="(app.innerWidth < 850)" loginContainerMobile>
            <div class="rightLoginLeaf mat-elevation-z8">
                <img src="../assets/GiG Monster Logo [stacked].svg" class="leftLeafOverlayMobile">
                <div class="roles rightLeafOverlay">
                    <h2 style="padding-bottom: 10px">Choose between:</h2>
                    <div class='loginButtonContainer'>
                        <button class="mat-flat-button role" [class.selected]="this.selectedRole == 97"
                            [class.Notselected]="this.selectedRole == 0" (click)="switchNav(374)">Venue</button>
                        <h3 style="padding:10px;">Or</h3>
                        <button class="mat-flat-button role" [class.selected]="this.selectedRole == 98"
                            [class.Notselected]="this.selectedRole == 0" (click)="switchNav(370)">Performer</button>
                    </div>
                </div>


                <form autocomplete="on" class="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <mat-form-field appearance="legacy" style="width: 100%">
                        <mat-label>Email</mat-label>
                        <input matInput type="input" id="username" required="true" formControlName="Username"
                            autocomplete="username">
                    </mat-form-field>
                    <mat-form-field appearance="legacy" style="width: 100%">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" required="true" formControlName="Password">
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="loginButton" type="submit">Login</button>
                </form>
                <div class="Form">
                    <div *ngIf="this.selectedRole == 97">
                        <i style="color: white;">New to GigMonster?</i>
                        <a style="padding-bottom: 20px;" [routerLink]="getSignupLink()">Sign up here as a Venue.</a>
                    </div>
                    <div *ngIf="this.selectedRole == 98">
                        <i style="color: white;">New to GigMonster?</i>
                        <a style="padding-bottom: 20px;" [routerLink]="getSignupLink()">Sign up here as a
                            Performer.</a>
                    </div>
                    <div *ngIf="this.selectedRole == 0">
                        <i style="color: white;">New to GigMonster?</i>
                        <a style="padding-bottom: 20px; text-decoration: underline; cursor: pointer;"
                            (click)="promptSignupRole()">Sign up here</a>
                    </div>
                    <div *ngIf="this.selectedRole != 0">
                        <i style="color: white;">Forgot password?</i>
                        <a style="padding-bottom: 20px;" [routerLink]="getForgotPasswordLink()">Click here.</a>
                    </div>
                    <div *ngIf="this.selectedRole == 0">
                        <i style="color: white;">Forgot password?</i>
                        <a style="padding-bottom: 20px; text-decoration: underline; cursor: pointer;"
                            (click)="promptForgotPasswordRole() ">Click here.</a>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- Display For Desktop View-->
        <ng-container *ngIf="(app.innerWidth >= 850)">
            <div class="mat-elevation-z8 loginContainer">
                <div class="leftLoginLeaf">
                    <div class="leftLeafOverlay">
                        <div class="roles">
                            <img src="../assets/GiG Monster Logo [stacked].svg" height="200px"
                                style="padding-top: 20px; opacity: 95%;">
                        </div>
                    </div>
                </div>
                <div class="rightLoginLeaf">
                    <div class="loginSuccess"></div>
                    <div class="roles rightLeafOverlay">
                        <h2 style="padding-bottom: 10px;">Choose between:</h2>
                        <div class='loginButtonContainer'>
                            <button class="mat-flat-button role" [class.selected]="this.selectedRole == 97"
                                [class.Notselected]="this.selectedRole == 0" (click)="switchNav(374)">Venue</button>
                            <h3 style="padding:10px;">Or</h3>
                            <button class="mat-flat-button role" [class.selected]="this.selectedRole == 98"
                                [class.Notselected]="this.selectedRole == 0" (click)="switchNav(370)">Performer</button>
                        </div>
                    </div>
                    <form autocomplete="on" class="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <mat-form-field appearance="legacy" style="width: 100%">
                            <mat-label>Email</mat-label>
                            <input matInput type="input" id="username" required="true" formControlName="Username"
                                autocomplete="username">
                        </mat-form-field>
                        <mat-form-field appearance="legacy" style="width: 100%">
                            <mat-label>Password</mat-label>
                            <input matInput type="password" required="true" formControlName="Password">
                        </mat-form-field>
                        <button mat-raised-button color="primary" class="loginButton" type="submit">Login</button>
                    </form>
                    <div class="Form">
                        <div *ngIf="this.selectedRole == 97">
                            <i style="color: white;">New to GigMonster?</i>
                            <a style="padding-bottom: 20px;" [routerLink]="getSignupLink()">Sign up here as a Venue.</a>
                        </div>
                        <div *ngIf="this.selectedRole == 98">
                            <i style="color: white;">New to GigMonster?</i>
                            <a style="padding-bottom: 20px;" [routerLink]="getSignupLink()">Sign up here as a
                                Performer.</a>
                        </div>
                        <div *ngIf="this.selectedRole == 0">
                            <i style="color: white;">New to GigMonster?</i>
                            <a style="padding-bottom: 20px; text-decoration: underline; cursor: pointer;"
                                (click)="promptSignupRole()">Sign up here</a>
                        </div>
                        <div *ngIf="this.selectedRole != 0">
                            <i style="color: white;">Forgot password?</i>
                            <a style="padding-bottom: 20px;" [routerLink]="getForgotPasswordLink()">Click here.</a>
                        </div>
                        <div *ngIf="this.selectedRole == 0">
                            <i style="color: white;">Forgot password?</i>
                            <a style="padding-bottom: 20px; text-decoration: underline; cursor: pointer;"
                                (click)="promptForgotPasswordRole() ">Click here.</a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>