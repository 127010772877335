<!--div [formGroup]="this.formGroup">
    <div class="" [formControlName]="this.fieldKey ">
        <span style="font-weight: 500;">{{field.label}}: <span
                style="color: red; font-weight: 500;">{{this.field.required
                ? '*' :''}}</span></span>
        <div class="field_submit multi-radio" *ngFor="let choice of field.choices | keyvalue ">
            <input type="radio" value="{{choice.key}}" name="field_id_907" class="field_submit multi-radio">
            <span *ngIf=" choice.value.value">{{choice.value.value}}</span>
            <span *ngIf="!choice.value.value">{{choice.value}}</span>
        </div>
    </div>
</div-->

<tr class="body ">
    <td valign="middle" class="label_field_153" style="font-weight: 500;">{{field.label}}<span
            style="color:red">*</span>:</td>
    <td valign="middle">
        <span *ngFor="let choice of field.choices | keyvalue">
            <label style="font-weight:normal" class="control control--radio aaaaaa ">
                <input type="radio" value="{{choice.key}}" name="field_id_153" class="field_submit multi-radio">
                <span *ngIf=" choice.value.value">{{choice.value.value}}</span>
                <span *ngIf="!choice.value.value">{{choice.value}}</span>
                <div class="control__indicator"></div>
            </label>
            <br>
        </span>
    </td>
</tr>