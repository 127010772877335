import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { AppidService } from '../base/services/appid/appid.service';
import { NavService } from '../base/services/nav/nav.service';

@Component({
    selector: 'app-bootstrapper',
    templateUrl: './bootstrapper.component.html',
    styleUrls: ['./bootstrapper.component.css']
})
export class BootstrapperComponent implements OnInit {

    constructor(
        public appID: AppidService,
        private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        console.log("Bootstrapping application...")
        console.log("Application ID: " + this.appID.appID);

        //handle v1 <-> v2 page transition. inspect for a query parameter called ?v1Data. do so only once.
        this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
            //if params contains v1Data, then base64 decode and then convert the result from json into an object.
            if (params.v1Data) {
                console.log("v1Data found. Converting to v2 format...");
                let v1Data = JSON.parse(atob(params.v1Data));
                console.log(v1Data);

                this.handleV1Data(v1Data);
            }
        });

    }

    handleV1Data(v1Data) {
        /**
         * the user is logged in in v1, so we need to use the data provided by v1data to generate a v2 login token,
         * ensuring that they can access other v2 pages if they need to (and so requests to the current v2 page succeed).
         * 
         * then we can redirect them to the page they care about. it's important that this is a very fast request so
         * users don't notice an obvious transition (nor see something they're not supposed to see, like the login page).
         */
    }

}
