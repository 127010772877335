import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { IFrameResizerDirective } from './directives/IFrameResizerDirective';

import { TrustHtmlPipe } from "./pipes/trustHTML";

import { SuperstructureBaseComponent } from './components/superstructure-base.component';
import { HeaderBaseComponent } from './components/header-base.component';
import { TopNavBaseComponent } from './components/top-nav-base.component';
import { CheckBoxBaseComponent } from './components/fields/checkbox-base.component';
import { DatePickerBaseComponent } from './components/fields/datepicker-base.component';
import { DropDownBaseComponent } from './components/fields/dropdown-base.component';
import { EmailConfirmationBaseComponent } from './components/fields/email-confirmation-base.component';
import { MultiCheckBoxBaseComponent } from './components/fields/multi-checkbox-base.component';
import { MultiLevelCheckBoxNoSelectBaseComponent } from './components/fields/multilevel-checkbox-noselect-base.component';
import { TimePickerBaseComponent } from './components/fields/timepicker-base.component';
import { TextAreaBaseComponent } from './components/fields/textarea-base.component';
import { TextFieldBaseComponent } from './components/fields/textfield-base.component';
import { LoginBaseComponent } from './components/auth-base.component';
import { FormBaseComponent } from './components/form-base.component';
import { MobileNavBaseComponent } from './components/mobile-nav-base.component';
import { PopupBaseComponent } from './components/popup-base.component copy';
import { SidenavBaseComponent} from './components/sidenav-base.component';
import { TableBaseComponent } from './components/table-base.component';
import { FormsModule } from '@angular/forms';
import { HrefToRouterLinkDirective } from './directives/HrefToRouterLinkDirective';

@NgModule({
  declarations: [
    PreventDoubleClickDirective,
    IFrameResizerDirective,
    TrustHtmlPipe,
    SuperstructureBaseComponent,
    HeaderBaseComponent,
    TopNavBaseComponent,
    CheckBoxBaseComponent,
    DatePickerBaseComponent,
    DropDownBaseComponent,
    EmailConfirmationBaseComponent,
    MultiCheckBoxBaseComponent,
    MultiLevelCheckBoxNoSelectBaseComponent,
    TimePickerBaseComponent,
    TextAreaBaseComponent,
    TextFieldBaseComponent,
    LoginBaseComponent,
    FormBaseComponent,
    HeaderBaseComponent,
    MobileNavBaseComponent,
    PopupBaseComponent,
    SidenavBaseComponent,
    TableBaseComponent,
    TopNavBaseComponent,
    HrefToRouterLinkDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    HrefToRouterLinkDirective
  ]
})
export class BaseModule { }
