import { Router } from "@angular/router";

/**
 * Navigates to the specified route with additional handling for dynamic front-end on the RADMAP platform.
 *
 * @param router - The Angular Router service used for navigation.
 * @param link - The target route to navigate to.
 * @returns void
 *
 * @remarks
 * A typical website would use static values in app-routing.module.ts to create routes, but this dynamically
 * generated front-end using dB values on the RADMAP platform requires this workaround to click on a nav button
 * more than once.
 *
 * @example
 * // Example of usage in a component:
 * navigate(this.router, '/desired-route');
 */
export const navigate = (router: Router, link: string): void => {
    //navigate and clear all query params
    //get all query params and set to null
    let queryParams = router.parseUrl(router.url).queryParams;
    for (let key in queryParams) {
        queryParams[key] = null;
    }


    // Add the workaround to force a navigation
    router.navigate(["/temp-route"]).then(() => {
        router.navigate(["/" + link], {
            queryParams: queryParams,
            queryParamsHandling: "merge"
        });
    });
}

