import { Component, OnInit } from '@angular/core';
import { EmailConfirmationBaseComponent } from 'src/app/base/components/fields/email-confirmation-base.component';

@Component({
  selector: 'app-easysuite-email-confirmation',
  templateUrl: './easysuite-email-confirmation.component.html',
  styleUrls: ['./easysuite-email-confirmation.component.css']
})
export class EasysuiteEmailConfirmationComponent extends EmailConfirmationBaseComponent implements OnInit {

  constructor() { super()}

  ngOnInit(): void {
  }

}
