<!--
<mat-checkbox [checked]="shouldBeChecked()" (change)="click($event.checked)" color="primary" [class.invalid]="!this.formGroup.controls[this.fieldKey].valid && this.formGroup.controls[this.fieldKey].touched">
    <p style="margin-right: 10px; margin-bottom: 0px;">{{field.label}}{{this.field.required ? ' *' : ''}}</p>
</mat-checkbox>
-->

<div>
    <td valign="middle" class="label_field_151" style="font-weight: 500;">{{field.label}}{{this.field.required ? ' *' :
        ''}}></td>
    <td valign="middle">
        <label class="control control--checkbox"><input type="checkbox" value="" class="field_submit multi-checkbox"
                name="field_id_151" checked="checked">
            <div class="control__indicator"></div>
        </label>
    </td>
</div>