import { Component, OnInit } from '@angular/core';
import { TextDollarsComponent } from 'src/app/gigmonster/components/fields/text-dollars/text-dollars.component';

@Component({
  selector: 'app-easysuite-text-dollars',
  templateUrl: './easysuite-text-dollars.component.html',
  styleUrls: ['./easysuite-text-dollars.component.css']
})
export class EasySuiteTextDollarsComponent extends TextDollarsComponent implements OnInit {

  constructor() {super()}

  ngOnInit(): void {
  }

}