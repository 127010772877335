import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BootstrapperComponent } from './bootstrapper/bootstrapper.component';
import { SuperstructureComponent } from './gigmonster/components/superstructure/superstructure.component';
import { SuperstructureEasysuiteComponent } from './easysuite/components/easysuite-superstructure/easysuite-superstructure.component';
import { RouteService } from './base/services/routes/routes.service';

const defaultRoutes: Routes = [
  { path: '**', component: BootstrapperComponent }
];

/**
 * Initializes the routes dynamically based on the routes fetched from the server.
 *
 * This function fetches the routes v2 using
 * the provided RouteService. The fetched routes are then used to create dynamic routes,
 * which are added to the default routes.
 *
 */
export function initializeUnauthNavRoutes(routeService: RouteService) {
    return async () => {
        try {
            const routes = await routeService.getRoutes();

            const dynamicRoutes: Routes = [];

            routes.forEach((route: any) => {

                let component;
                //this is a temporary solution till we combine both EZS superstructure and GM superstructure into a single superstructure
                if(route.unauthNavLabel == 'venues' || route.unauthNavLabel == 'performers'){
                    component = SuperstructureComponent;
                }else{
                    component = SuperstructureEasysuiteComponent;
                }
                
                dynamicRoutes.push(
                    { path: `${route.unauthNavLabel}/register`, component: component },
                    { path: `${route.unauthNavLabel}/forgotpassword`, component: component }
                );
            });

            // Add the dynamic routes to the default routes
            dynamicRoutes.forEach(route => defaultRoutes.unshift(route));
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };
}
  

@NgModule({
  imports: [RouterModule.forRoot(defaultRoutes, { initialNavigation: 'enabledNonBlocking' })],
  exports: [RouterModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUnauthNavRoutes,
      deps: [RouteService],
      multi: true
    }
  ]
})
export class AppRoutingModule { }