<ng-container>
    <ng-template #subItem let-item>
        <!--<h1>template</h1>-->
        <li class="">
            <a [class.active]="isSideNavActive(item.link)" [class.selectedLi]="isSideNavActive(item.link)"
                (click)="navigate(item.link)">{{item.label}}</a>
        </li>
    </ng-template>

    <ng-template #rootItem let-item>
        <li class="level0 has-sub active">
            <a [class.selectedLi]="isActive(item.path)" (click)="navigate(item.path)">{{item.data.label |
                uppercase}}</a>
            <span class="submenu-button" (click)="expandOrCollapse(item)"
                [class.submenu-opened]="item.collapsed"></span>
            <!--<h1>{{isActive(item.path)}}</h1>-->
            <ul class="nav_menu" *ngIf="item.childNavs && item.childNavs.length > 0 && item.collapsed === true"
                [class.nav_menu_full]="item.collapsed" [class.selectedLi]="isActive(item.path)">
                <ng-container *ngFor="let child of item.childNavs">
                    <ng-container *ngTemplateOutlet="subItem; context: {$implicit: child}"
                        class="selectedLi"></ng-container>
                </ng-container>
            </ul>
        </li>
    </ng-template>

    <div id="menu" *ngIf="this.data.length > 0">
        <nav class="nav" id="multimenu">
            <div id="head-mobile"></div>
            <div class="button" (click)="collapseMobileNav()" [class.menu-opened]="showMobileNav"></div>

            <ul class="nav_menu" [class.nav_menu_full]="showMobileNav">
                <ng-container *ngIf="this.psuedoLoginInfo == null">
                    <ng-container *ngFor="let item of data">
                        <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: item}"></ng-container>
                    </ng-container>

                    <li *ngIf="this.isRegMode" class="level0 has-sub">
                        <a style="cursor: pointer;" [class.active.selected]="isActive">{{this.regRole | uppercase}}</a>
                    </li>

                    <li class="level0 has-sub">
                        <a (click)="logout()">{{this.isRegMode ? "Back to Login" : "LOGOUT"}}</a>
                    </li>
                </ng-container>

                <ng-container *ngIf="this.psuedoLoginInfo != null">
                    <ng-container *ngFor="let item of this.psuedoLoginInfo.LoginNavs | keyvalue">
                        <li class="level0">
                            <a style="cursor: pointer;">{{item.value + " Login" | uppercase}}</a>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </nav>
    </div>
</ng-container>