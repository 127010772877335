import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../../interfaces/field';

@Component({
  selector: 'app-datepicker-base',
  template: ``,
  styles: []
})
export class DatePickerBaseComponent {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: FormGroup;

    constructor() { }

}