import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AppidService {

    public appID: number;
    public roles = null;

    constructor(private route: Router) {
        this.appID = this.getappID();
        this.roles = this.getRoles(this.appID);
    }

    /**
     * GM:
     * app.gigmonster.live
     * gig.v2.stg.improvation.us
     * *.reviewapps.gigit.rocks
     * 
     * LOT:
     * app2.lotterease.com
     * dev.lotterease.com
     * *.reviewapps.lotterease.com
     */
    getappID(): any {

        let url = window.location.hostname;
        let localhostAppID = 434693;

        //if localhost, return defaultAppID
        if (url.includes('localhost')) {
            return localhostAppID;
        }
        //if url contains gigmonster or gigit, return 434693
        if (url.includes('gigmonster') || url.includes('gigit') || url.includes('gig')) {
            return 434693;
        }

        //if url contains enr or enrollease, return 985146
        if (url.includes('enr') || url.includes('enrollease')) {
            return 985146;
        }

        //if url contains lot or lotterease, return 795418
        if (url.includes('lot') || url.includes('lotterease')) {
            return 795418;
        }

        //if url contains fdz or foodease, return 985135
        if (url.includes('fdz') || url.includes('foodease')) {
            return 985135;
        }

        //if url contains sup or supervisease, return 985151
        if (url.includes('sup') || url.includes('supervisease')) {
            return 985151;
        }

        //if url contains jrn or journease, return 985143
        if (url.includes('jrn') || url.includes('journease')) {
            return 985143;
        }

        //if url contains pur or purchasease, return 165112
        if (url.includes('pur') || url.includes('purchasease')) {
            return 165112;
        }

        if (url.includes('frontend--')) {
            return 434693;
        }
    }

    getRoles(appID) {
        if (appID == 434693) {
            return {
                '97': 'Venue',
                '98': 'Performer'
            };
        } else if (appID == 795418) {
            return {
                '269': 'Staff',
                '270': 'Parent'
            };
        } else return null;
    }
}
