import { Component, OnInit } from '@angular/core';
import { DatePickerBaseComponent } from 'src/app/base/components/fields/datepicker-base.component';

@Component({
  selector: '[app-easysuite-datepicker]',
  templateUrl: './easysuite-datepicker.component.html',
  styleUrls: ['./easysuite-datepicker.component.css']
})
export class EasysuiteDatepickerComponent extends DatePickerBaseComponent implements OnInit {

  constructor() { super()}

  ngOnInit(): void {
  }

}
