import { Component, Input, OnInit } from '@angular/core';
import { TopNavBaseComponent } from 'src/app/base/components/top-nav-base.component';
import { SidenavComponent } from 'src/app/gigmonster/components/sidenav/sidenav.component';
import { HttpClient } from '@angular/common/http';
import { Route, Router } from '@angular/router';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { PsuedoLoginPageInformation } from '../easysuite-superstructure/easysuite-superstructure.component';

@Component({
    selector: 'app-easysuite-top-nav',
    templateUrl: './easysuite-top-nav.component.html',
    styleUrls: ['./easysuite-top-nav.component.css']
})
export class EasysuiteTopNavComponent extends TopNavBaseComponent implements OnInit {

    @Input() psuedoLoginInfo: PsuedoLoginPageInformation = null;

    data: any = [];

    constructor(public sideNav: SidenavComponent,
        public http: HttpClient,
        public router: Router,
        public navService: NavService,
        public authService: AuthService,
        public activated: ActivatedRoute,
        public app: AppComponent,
        public activatedRoute: ActivatedRoute) {
        super(sideNav, http, router, navService, authService, activated, app);
    }

    /**
     * overrides ngonicit in topnavbase, used because we care about descendants as well
     */
    ngOnInit(): void {
        this.data = [];
        let tempData = [];

        //find all root routes
        let filteredRoutes = this.router.config.filter(x => x.path.split("/").length == 1 && x.path != "auth" && x.path != "**");

        filteredRoutes.forEach(x => {
            tempData.push(x);
        });

        tempData = tempData.sort(this.asIsOrder);

        //for each root route, find all descendants
        tempData.forEach(route => {
            let tempRoute = { ...route };

            // let rootPath = tempRoute.path.split("/")[0];

            // //recursively find first descendant route where data.hasPage is true
            // let firstDescendant = this.router.config.find(r => r.path.split("/")[0] == rootPath && tempRoute.path != r.path && r.data != null && r.data.hasPage);
            // let index = 1;
            // while (firstDescendant != null && firstDescendant.data.hasPage == false) {
            //     firstDescendant = this.router.config.find(r => {
            //         let baseMatch = r.path.split("/")[0] == rootPath && tempRoute.path != r.path && r.data != null && r.data.hasPage;
            //         return baseMatch && r.path.split("/")[index] == firstDescendant.path.split("/")[index++];
            //     });
            // }

            // if (firstDescendant == null) {
            //     console.error("No descendant route found for " + tempRoute.path + ", does it have a page attached to it in RADMIN?");
            // }

            // if (firstDescendant != null) {
            //     tempRoute.path = firstDescendant.path;
            // }

            tempRoute.childNavs = this.buildNavArray(this.router.config, tempRoute.path, 0);

            //sort through tempRoute.childNavs by sortOrder
            tempRoute.childNavs = tempRoute.childNavs.sort((a, b) => {
                return Number.parseInt(a.sortOrder) > Number.parseInt(b.sortOrder) ? 1 : -1;
            });


            //iterate through tempRoute.childNavs without using forEach function
            //because forEach function doesn't allow for breaking out of the loop
            for (let i = 0; i < tempRoute.childNavs.length; i++) {
                let x = tempRoute.childNavs[i];
                console.log(x);
                if (x.hasPage) {
                    tempRoute.path = x.link;
                    break;
                }
            }

            this.data.push(tempRoute);
        });

        console.log(this.data);
    }

    buildNavArray(routes: Route[], basepath: string, level: number): any {
        let newItems = [];

        let thisLevelRoutes = routes.filter(x => {
            let validPath = true;
            if (basepath != "") {
                validPath = x.path.indexOf(basepath) == 0;
            }
            return x.path.split("/").length == level + 2 && validPath
        });

        thisLevelRoutes.forEach(realItem => {
            let item = { ...realItem };

            let itemChildren = this.buildNavArray(routes, item.path + "/", level + 1);
            itemChildren.sort(this.asIsOrder);

            let link = "/" + item.path;
            let originalLink = link;
            if (!item.data.hasPage && itemChildren.length > 0 && itemChildren[0].hasPage) {
                link = itemChildren[0].link;
            }

            let newItem = {
                label: item.data.label,
                link: link,
                children: itemChildren,
                hasPage: item.data.hasPage,
                collapsed: true,
                originalLink: originalLink,
                sortOrder: item.data.sortOrder
            };

            newItems.push(newItem);
        });

        return newItems;
    }

    isActive(link): boolean {
        //check if this.router.url minus any http parameters matches the link
        let url = this.router.url.split("?")[0].split('/')[1];
        return url == link;
    }

    asIsOrder(a, b) {
        return Number.parseInt(a.data.sortOrder) > Number.parseInt(b.data.sortOrder) ? 1 : -1;
    }
}
