import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TableBaseComponent, TableDataSource } from 'src/app/base/components/table-base.component';
import { ContentService } from 'src/app/base/services/content/content.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-easysuite-table',
    templateUrl: './easysuite-table.component.html',
    styleUrls: ['./easysuite-table.component.css']
})
export class EasysuiteTableComponent extends TableBaseComponent implements OnInit, AfterViewInit {

    paginatorInfo: EasysuitePaginatorInfo;
    Header: any;

    constructor(public contentService: ContentService, public router: Router, public route: ActivatedRoute) {
        super(contentService, router, route)
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.paginatorInfo = new EasysuitePaginatorInfo(this.dataSource, this.pageID, this.table.tableID);
    }

    ngAfterViewInit(): void {
        this.dataSource.loadData(this.table.tableID, '', 'asc', 1, 25, this.pageID);
    }

    toggleSearch() {
        if (this.showSearch) {
            //clear filter
            this.dataSource.loadData(this.table.tableID, '', 'asc', this.paginatorInfo.pageIndex, this.paginatorInfo.pageSize, this.pageID);
        }
        this.showSearch = !this.showSearch;
    }

    public getHeader(Header): any {
        for (let i = 0; i < this.table.columns.length; i++) {
            Header = this.table.columns[i].header
            //console.log(this.table.columns[i].header)
            if (Header == "Edit" || Header == "Delete") {
                return Header
            }
        }
    }
}


export class EasysuitePaginatorInfo {
    public pageSize: number;
    public pageIndex: number;
    public totalPages: number = 0;
    public dataLength: number;

    private pageID: number;
    private tableID: number;
    private datasource: TableDataSource;

    constructor(dataSource: TableDataSource, pageID: number, tableID: number) {
        this.datasource = dataSource;
        this.pageID = pageID;
        this.tableID = tableID;

        this.pageIndex = 1;
        this.pageSize = 25;

        this.datasource.connect().subscribe(data => {
            this.dataLength = this.datasource.dataLength;
            if (this.totalPages == 0) this.totalPages = Math.ceil(this.dataLength / this.pageSize);

            console.log("subscribe called");
        });
    }

    public canGoBack(): boolean {
        return this.pageIndex > 1;
    }

    public canGoForward(): boolean {
        return this.pageIndex < this.totalPages;
    }

    public goBackOnePage(): void {
        if (this.pageIndex > 1) {
            this.pageIndex--;
            this.datasource.loadData(this.tableID, '', 'asc', this.pageIndex, this.pageSize, this.pageID);
        }
    }

    public goBackAllPages(): void {
        if (this.pageIndex > 1) {
            this.pageIndex = 1;
            this.datasource.loadData(this.tableID, '', 'asc', this.pageIndex, this.pageSize, this.pageID);
        }
    }

    public goForwardOnePage(): void {
        if (this.pageIndex < this.totalPages) {
            this.pageIndex = this.pageIndex + 1;
            this.datasource.loadData(this.tableID, '', 'asc', this.pageIndex, this.pageSize, this.pageID);
        }
    }

    public goForwardAllPages(): void {
        if (this.pageIndex < this.totalPages) {
            this.pageIndex = this.totalPages;
            this.datasource.loadData(this.tableID, '', 'asc', this.pageIndex, this.pageSize, this.pageID);
        }
    }

    public setCount($event): void {
        this.pageSize = $event;
        this.pageIndex = 1;
        this.totalPages = Math.ceil(this.dataLength / this.pageSize);
        this.datasource.loadData(this.tableID, '', 'asc', this.pageIndex, this.pageSize, this.pageID);
    }
}