<div class="dropdown" [formGroup]="this.formGroup">
    <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>{{field.label}}</mat-label>
        <mat-select [formControlName]="this.fieldKey" (selectionChange)="onSelectionChange($event, field.choices)">
            <mat-option *ngFor="let choice of field.choices | keyvalue: originalOrder" [value]="choice.key">
                <span *ngIf="choice.value.value">{{choice.value.value}}</span>
                <span *ngIf="!choice.value.value">{{choice.value}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>