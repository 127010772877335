import { Component, OnInit } from '@angular/core';
import { MultiCheckBoxBaseComponent } from 'src/app/base/components/fields/multi-checkbox-base.component';

@Component({
  selector: 'app-easysuite-multi-checkbox',
  templateUrl: './easysuite-multi-checkbox.component.html',
  styleUrls: ['./easysuite-multi-checkbox.component.css']
})
export class EasysuiteMultiCheckboxComponent extends MultiCheckBoxBaseComponent implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
  }

}
