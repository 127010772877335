import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form } from 'src/app/base/interfaces/form';
import { UntypedFormControl, UntypedFormGroup, FormsModule, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-form-base',
    template: ``,
    styles: []
})
export class FormBaseComponent implements OnInit {
    @Input() form: Form;
    @Input() showSubmitButton: boolean = true;
    @Input() parentSubmitButton: Observable<boolean> = null;
    @Input() pageID: Number = null;

    @Output() formSubmitted = new EventEmitter<Record<number, string>>();

    formGroup: UntypedFormGroup;

    constructor(public route: ActivatedRoute) { }

    ngOnInit() {
        if (this.parentSubmitButton != null) {
            this.parentSubmitButton.subscribe(x => {
                this.submitForm();
            })
        }

        this.formGroup = new UntypedFormGroup({}, {
            updateOn: 'change'
        });

        for (let key in this.form.fields) {
            let field = this.form.fields[key];
            let newControl = new UntypedFormControl();

            if (field.required) newControl.addValidators(Validators.required);
            if (field.value) newControl.setValue(field.value);

            this.formGroup.addControl(key, newControl);
        }

        if (this.route.snapshot.data.registrationMode && this.pageID == 609) {
            this.route.queryParams.subscribe(urlParams => {
                this.formGroup.controls["1"].setValue(urlParams["emailAddress"]);
                this.formGroup.controls["3"].setValue(urlParams["phone"]);
                this.formGroup.controls["29"].setValue(urlParams["actName"]);
                this.formGroup.controls["29"].setValue(urlParams["organizationName"]);
                this.formGroup.controls["33"].setValue(urlParams["website"]);
            });
        }
    }

    submitForm() {
        if (!this.formGroup.valid) {
            for (let key in this.form.fields) {
                if (this.formGroup.get(key).invalid) {
                    this.formGroup.get(key).markAsTouched();
                }
            }
            return;
        }
        //form is valid, submit it
        let fieldsToSubmit: Record<number, string> = {};
        for (let key in this.form.fields) {
            fieldsToSubmit[key] = this.formGroup.get(key).value;
        }

        this.formSubmitted.emit(fieldsToSubmit);
    }

    cancelForm() {
        //go to previous page
        window.history.back();
    }

    asIsOrder(a, b) {
        return a.value.fieldOrder > b.value.fieldOrder ? 1 : -1;
    }

}

