import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { HeaderBaseComponent } from 'src/app/base/components/header-base.component';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { WorkflowService } from 'src/app/base/services/workflow/workflow.service';

@Component({
    selector: 'app-easysuite-header',
    templateUrl: './easysuite-header.component.html',
    styleUrls: ['./easysuite-header.component.css']
})
export class EasysuiteHeaderComponent extends HeaderBaseComponent implements OnInit {

    constructor(public auth: AuthService, public nav: NavService, public app: AppComponent, public workflow: WorkflowService) {
        super(auth, nav, app, workflow);
    }

    ngOnInit(): void {
    }

}
