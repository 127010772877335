<!--
<ng-container>
    <div class="tableButtons">
        <button mat-raised-button color="primary" *ngFor="let button of table.buttons | keyvalue" (click)="handleButtonClick(button.value)">{{button.value.Label}}</button>
    </div>

    <div class="searchAndExport">
        <div class="stupidCentering">
            <div *ngIf="this.dataSource.dataLength >= 25">
                <p class="searchLink" (click)="toggleSearch()">{{this.showSearch ? "Hide Search" : "Show Search"}}</p>
                <div *ngIf="this.showSearch" class="searchFields">
                    <ng-container *ngFor="let col of this.table.columns; let i=index;">
                        <mat-form-field appearance="outline" *ngIf="col.searchable">
                            <mat-label>{{col.header}}</mat-label>
                            <input (keyup)="filter($event, i)" matInput placeholder="{{col.header}}">
                        </mat-form-field>
                    </ng-container>
                </div>
            </div>
        </div>
        <!-- <div class="exportImages">
            <img src='../../../assets/pdf.png' alt='pdf' (click)="exportPDF()">
            <img src='../../../assets/txt.png' alt='txt' (click)="exportTxt()">
            <img src='../../../assets/xls.png' alt='xls' (click)="exportExcel()">
        </div> --
    </div>

    <table matSort (matSortChange)="sortData($event)" mat-table [dataSource]="dataSource">
        <ng-container *ngFor="let column of table.columns; let i=index" [matColumnDef]="column.header">
            <th mat-header-cell *matHeaderCellDef [mat-sort-header]="i.toString()" [disabled]="column.sortable == '0'"> {{column.header}} </th>
            <td mat-cell *matCellDef="let row" [class.isExpand]='column.collapsible && isExpand' (click)="isExpandToggle(column)">
                <ng-container *ngIf="!hasLink(row[i]) && !hasCheckbox(row[i])">
                    {{row[i]}}
                </ng-container>
                <ng-container *ngIf="row[i] === '[[edit]]'">
                    EDIT (needs callback!)
                </ng-container>
                <ng-container *ngIf="row[i] === '[[delete]]'">
                    DELETE (needs callback!)
                </ng-container>
                <ng-container *ngIf="hasCustomLink(row[i])">
                    <a (click)="tableLinkClick(buildLink(row[i]))">{{buildLink(row[i]).linkText}}</a>
                </ng-container>
                <ng-container *ngIf="hasCallbackLink(row[i])">
                    <a (click)="handleCallbackLink(buildCallbackLink(row[i]))">{{buildCallbackLink(row[i]).linkText}}</a>
                </ng-container>
                <ng-container *ngIf="hasCheckbox(row[i])">
                    <mat-checkbox (change)="checkboxChange(row[i])"></mat-checkbox>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnStrings"></tr>
        <tr mat-row *matRowDef="let row; columns: columnStrings; "></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="table.columns.length">
                <ng-container *ngIf="this.initialTableLoad">Loading...</ng-container>
                <ng-container *ngIf="!this.initialTableLoad">No results found.</ng-container>
            </td>
        </tr>
    </table>

    <mat-paginator [length]="this.table.totalRecords" [pageSize]="25 " [pageSizeOptions]="[5, 10, 25, 100] " aria-label="Select page of table "></mat-paginator>
</ng-container>
-->

<div *ngIf="this.dataSource.dataLength >= 25" id="div_search">
    <p>
        <a id="tb_search" (click)="toggleSearch()"><span id="text_search">{{this.showSearch ? "Hide Search" :
                "ShowSearch"}}</span></a>
        <span id="text_search_span" style="display:none">Search</span>
    </p>
</div>

<table *ngIf="this.showSearch" class="tbSeachField" style="margin-bottom: 10px;" cellspacing="1">
    <p class="tbSeachField">Enter the term(s) you would like to search for.
        <tbody>
            <tr *ngFor="let column of table.columns; let i=index">
                <ng-container *ngIf="column.searchable">
                    <td>{{column.header}}</td>
                    <td><input type="text" class="search_field" (keyup)="filter($event, i)"></td>
                </ng-container>
            </tr>
        </tbody>
</table>

<div *ngIf="paginatorInfo != null && (true || this.dataSource.dataLength > 25)">
    <ul class="pagination">
        <li [class.disabled]="!paginatorInfo.canGoBack()"><a (click)="paginatorInfo.goBackAllPages()">&lt;&lt;</a>
        </li>
        <li [class.disabled]="!paginatorInfo.canGoBack()"><a (click)="paginatorInfo.goBackOnePage()">&lt;</a></li>
        <li>
            <select #paginatorPageSize name="lstItemView" id="item_view" (change)="paginatorInfo.setCount(paginatorPageSize.value)">
                <option value="25" [selected]="paginatorInfo.pageSize == 25">25</option>
                <option value="50" [selected]="paginatorInfo.pageSize == 50">50</option>
                <option value="100" [selected]="paginatorInfo.pageSize == 100">100</option>
                <option value="500" [selected]="paginatorInfo.pageSize == 500">500</option>
                <option value="1000" [selected]="paginatorInfo.pageSize == 1000">1000</option>
            </select>
        </li>
        <li [class.disabled]="!paginatorInfo.canGoForward()"><a (click)="paginatorInfo.goForwardOnePage()">&gt;</a>
        </li>
        <li [class.disabled]="!paginatorInfo.canGoForward()"><a (click)="paginatorInfo.goForwardAllPages()">&gt;&gt;</a>
        </li>
    </ul>
</div>

<div id="table_css" class="table1" style="position:relative; margin-right: 5px;">
    <span class="export_button" style="display: inline-block;float:right;position:absolute;z-index:999;top:-47px;right:0;">

        <a href="https://rad.frog10.improvation.us/services/export_files/export_file_page_205_9860_9860.pdf"
            class="btn btn-success btn-export-pdf " target="_blank"
            style="padding: 0;box-shadow: 0px 1px 0px 0px #670808;" download="export_file_page_205_9860_9860.pdf"><img
                src="../../../assets/easysuite/pdf.png" style="border-radius: 4px;">
        </a>
        <span style="margin-right: 5px;"></span>

    <a href="https://rad.frog10.improvation.us/services/export_files/export_file_page_205_9860_9860.txt" class="btn btn-success btn-export-txt " target="_blank" style="padding: 0;box-shadow: 0px 1px 0px 0px #670808;" download="export_file_page_205_9860_9860.txt"><img src="../../../assets/easysuite/txt.png" style="border-radius: 4px;">
    </a>
    <span style="margin-right: 5px;"></span>

    <a href="https://rad.frog10.improvation.us/services/export_files/export_file_page_205_9860_9860.xls" class="btn btn-success btn-export-xls " target="_blank" style="padding: 0;box-shadow: 0px 1px 0px 0px #670808;" download="export_file_page_205_9860_9860.xls"><img src="../../../assets/easysuite/xls.png" style="border-radius: 4px;"></a>
    </span>

    <div id="table-scroll" class="table-scroll">
        <div class="table-wrap">

            <table id="rt1" class="rt cf main-table">
                <tbody>
                    <tr>
                        <th title="" style="vertical-align:top; text-align:center" *ngFor="let column of table.columns; let i=index">{{column.header}}</th>
                    </tr>

                    <tr *ngFor="let row of this.dataSource.connect() | async;">
                        <td style="vertical-align:top; text-align:left" *ngFor="let col of row; let i=index">
                            <span *ngIf="this.table.columns[i].action != 1 && this.table.columns[i].action != 2">
                                <span class="ng-binding" *ngIf="!hasLink(col) && !hasCheckbox(col)">{{col}}</span>
                            </span>
                            <span *ngIf="this.table.columns[i].action == 1">
                                <span class="ng-binding" *ngIf="!hasLink(col) && !hasCheckbox(col)"><img
                                        src="../../../assets/easysuite/b_edit.png" alt=""
                                        style="margin-right: auto; margin-left: auto; display: block;"></span>
                            </span>
                            <span *ngIf="this.table.columns[i].action == 2">
                                <span class="ng-binding" *ngIf="!hasLink(col) && !hasCheckbox(col)"><img
                                        src="../../../assets/easysuite/b_drop.png" alt=""
                                        style="margin-right: auto; margin-left: auto; display: block;"></span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="paginatorInfo != null && (true || this.dataSource.dataLength > 25)">
        <ul class="pagination">
            <li [class.disabled]="!paginatorInfo.canGoBack()"><a (click)="paginatorInfo.goBackAllPages()">&lt;&lt;</a>
            </li>
            <li [class.disabled]="!paginatorInfo.canGoBack()"><a (click)="paginatorInfo.goBackOnePage()">&lt;</a></li>
            <li>
                <select #paginatorPageSize name="lstItemView" id="item_view" (change)="paginatorInfo.setCount(paginatorPageSize.value)">
                    <option value="25" [selected]="paginatorInfo.pageSize == 25">25</option>
                    <option value="50" [selected]="paginatorInfo.pageSize == 50">50</option>
                    <option value="100" [selected]="paginatorInfo.pageSize == 100">100</option>
                    <option value="500" [selected]="paginatorInfo.pageSize == 500">500</option>
                    <option value="1000" [selected]="paginatorInfo.pageSize == 1000">1000</option>
                </select>
            </li>
            <li [class.disabled]="!paginatorInfo.canGoForward()"><a (click)="paginatorInfo.goForwardOnePage()">&gt;</a>
            </li>
            <li [class.disabled]="!paginatorInfo.canGoForward()"><a (click)="paginatorInfo.goForwardAllPages()">&gt;&gt;</a></li>
        </ul>
    </div>
</div>