import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Table, TableColumn } from 'src/app/base/interfaces/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription, tap } from 'rxjs';
import { ContentService } from 'src/app/base/services/content/content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PageActionResponse } from 'src/app/base/interfaces/pageActionResponse';
import { Button } from 'src/app/base/interfaces/button';

@Component({
  selector: 'app-table-base',
  template: ``,
  styles: []
})

export class TableBaseComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() table: Table;
    @Input() reloadNotifier: Observable<any>;
    @Input() pageID: number;

    @Output() pageAction: EventEmitter<PageActionResponse> = new EventEmitter<PageActionResponse>();
    @Output() needsLoadingAnimation: EventEmitter<boolean> = new EventEmitter<boolean>();

    private reloadSubscription: Subscription;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    dataSource: TableDataSource;
    columnStrings: string[] = null;

    //store any IDs selected by checkbox for future usage
    selectedIDs: any[] = [];
    
    //if reload params are set, use it.
    reloadParams: any[] = [];

    public initialTableLoad: boolean = true;
    public isExpand:boolean = true;
    showSearch: boolean = false;
    constructor(public contentService: ContentService, public router: Router, public route: ActivatedRoute) { }

    ngOnInit(): void {
        this.reloadSubscription = this.reloadNotifier.subscribe((x) => {
            this.reloadParams = [];
            this.dataSource.refetchDataOnLoad();
            this.dataSource.loadData(this.table.tableID, '', 'asc', 1, this.paginator.pageSize, this.pageID, x);
        });

        this.columnStrings = [];
        this.table.columns.forEach(x => this.columnStrings.push(x.header));
        this.dataSource = new TableDataSource(this.contentService, this.table.columns.length);

        this.dataSource.loading$.subscribe(x => {
            if (x.loading) {
                this.needsLoadingAnimation.emit(true);
                this.initialTableLoad = true;
            } else {
                this.initialTableLoad = false;
                this.table.totalRecords = x.size;
                this.needsLoadingAnimation.emit(false);
            }
        })

        this.route.queryParams.subscribe(x => {
            //merge table initialParams with query params
            let params = Object.assign({}, this.table.InitialParams, x);
            this.table.InitialParams = null;
            this.dataSource.loadData(this.table.tableID, '', 'asc', 1, 25, this.pageID, params);
        });
    }
    
    isExpandToggle(column: TableColumn){
        if (column.collapsible) this.isExpand=!this.isExpand;
    }

    ngAfterViewInit(): void {
        this.paginator.page.subscribe((x: PageEvent) => {
            this.dataSource.loadData(this.table.tableID, '', 'asc', x.pageIndex + 1, x.pageSize, this.pageID);
        });
    }

    ngOnDestroy(): void {
        if (this.reloadSubscription != null) this.reloadSubscription.unsubscribe();
    }

    exportTxt(): void {
        this.needsLoadingAnimation.emit(true);
        this.contentService.exportTable(this.table.tableID, 'txt').subscribe({
            next: (x) => {
                let dataType = "text/plain";
                let binaryData = [];
                binaryData.push(x);

                let downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                downloadLink.setAttribute('download', "export.txt");
                document.body.appendChild(downloadLink);
                
                downloadLink.click();
                downloadLink.parentNode.removeChild(downloadLink);
                this.needsLoadingAnimation.emit(false);
            }
        });
    }

    exportExcel(): void {
        this.needsLoadingAnimation.emit(true);
        this.contentService.exportTable(this.table.tableID, 'xls').subscribe({
            next: (x) => {
                let dataType = "application/octet-stream";
                let binaryData = [];
                binaryData.push(x);

                let downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                downloadLink.setAttribute('download', "export.xlsx");
                document.body.appendChild(downloadLink);
                
                downloadLink.click();
                downloadLink.parentNode.removeChild(downloadLink);
                this.needsLoadingAnimation.emit(false);
            }
        });
    }

    exportPDF(): void {
        this.needsLoadingAnimation.emit(true);
        this.contentService.exportTable(this.table.tableID, 'pdf').subscribe({
            next: (x) => {
                let dataType = "application/octet-stream";
                let binaryData = [];
                binaryData.push(x);

                let downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                downloadLink.setAttribute('download', "export.pdf");
                document.body.appendChild(downloadLink);
                
                downloadLink.click();
                downloadLink.parentNode.removeChild(downloadLink);
                this.needsLoadingAnimation.emit(false);
            }
        });
    }

    sortData(event) {
        this.dataSource.loadData(this.table.tableID, event.active, event.direction, this.paginator.pageIndex + 1, this.paginator.pageSize, this.pageID);
    }
    

    toggleSearch() {
        if (this.showSearch) {
            //clear filter
            this.dataSource.loadData(this.table.tableID, '', 'asc', this.paginator.pageIndex + 1, this.paginator.pageSize, this.pageID);
        }
        this.showSearch = !this.showSearch;
    }

    filter($event, colIndex) {
        //get value of field based on event
        let value = $event.target.value;
        this.dataSource.filterData(colIndex, value);
    }

    buildLink(rowText: string): any {
        //convert [[link]]:{"linkText":"Details","pageID":999,"additionalParameters":[]} to object
        let link = JSON.parse(rowText.replace("[[link]]:", ""));
        return {
            linkText: link.linkText,
            pageID: link.pageID,
            additionalParameters: link.additionalParameters
        };
    }

    hasLink(rowText: string): boolean {
        if (rowText == null) return false;
        
        rowText = rowText.toString();
        return rowText.indexOf("[[link]]") > -1 || rowText.indexOf("[[edit]]") > -1 || rowText.indexOf("[[delete]]") > -1 || rowText.indexOf("[[callbackLink]]") > -1;
    }

    hasCustomLink(rowText: string): boolean {
        if (rowText == null) return false;

        rowText = rowText.toString();
        return rowText.indexOf("[[link]]") > -1;
    }

    tableLinkClick(link: any) {
        let params = {page: link.pageID};
        //iterate through action.options["additionalParams"] record and add to params
        for (let paramKey in link.additionalParameters) {
            params[paramKey] = link.additionalParameters[paramKey];
        }
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: params,
                queryParamsHandling: 'merge'
            });
    }

    buildCallbackLink(rowText: string): any {
        let link = JSON.parse(rowText.replace("[[callbackLink]]:", ""));
        return {
            linkText: link.linkText,
            callback: link.callback,
            additionalParameters: link.additionalParameters
        };
    }

    hasCallbackLink(rowText: string): boolean {
        if (rowText == null) return false;

        rowText = rowText.toString();
        return rowText.includes("[[callbackLink]]");
    }

    handleCallbackLink(link: any): void {
        link.additionalParameters["tableCheckboxes"] = this.selectedIDs;

        this.contentService.submitTableLink(this.table.tableID, link.callback, link.additionalParameters).subscribe({
            next: (x) => {
                this.pageAction.emit(x);
            },
            error: (err) => console.error(err)
        });
    }

    checkboxChange(row) {
        let rowText: string = row.toString();
        let id = rowText.split(":")[1];

        //remove item from selectedIDs if it is already in there, add it if not
        if (this.selectedIDs.includes(id)) {
            this.selectedIDs.splice(this.selectedIDs.indexOf(id), 1);
        } else {
            this.selectedIDs.push(id);
        }
    }

    hasCheckbox(rowText: string): boolean {
        if (rowText == null) return false;

        rowText = rowText.toString();
        return rowText.includes("[[checkbox]]");
    }

    handleButtonClick(button: Button): void {
        if (button.Type == "link") {
            //check if link is just applying a "page" parameter
            if (button.Link.indexOf("page=") > -1) {
                this.router.navigateByUrl(this.router.url + button.Link);
            }
        } else if (button.Type == "callback") {
            if (button.Options == null) button.Options = {};
            button.Options["tableCheckboxes"] = this.selectedIDs;

            //merge reloadparams into options
            if (this.reloadParams != null) {
                for (let paramKey in this.reloadParams) {
                    button.Options[paramKey] = this.reloadParams[paramKey];
                }
            }

            this.contentService.submitTableLink(this.table.tableID, button.Label, button.Options).subscribe({
                next: (x) => {
                    this.pageAction.emit(x);
                },
                error: (err) => console.error(err)
            });
        }
    }
    
}

export class TableDataSource extends DataSource<string> {
    private dataSubject = new BehaviorSubject<string[]>([]);
    private loadingSubject = new BehaviorSubject<any>({});

    public loading$ = this.loadingSubject.asObservable();

    private data = [];
    public dataLength = 0;

    private getDataAgain = true;

    constructor(private contentService: ContentService, columnCount: number) {
        super();
    }

    connect(): Observable<string[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer) {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }

    refetchDataOnLoad() {
        this.getDataAgain = true;
    }

    loadData(tableID: number, filter = '', sortOrder = 'asc', page = 1, pageSize = 25, pageID: number, additionalParameters?: Record<string, any>): void {
        this.loadingSubject.next({
            loading: true,
            size: 0
        });

        /**
         * We're switching to client-side pagination and sorting because the server
         * really doesn't need to handle it except for tables that are absolutely massive.
         * We fetch the data once on page-load and then just handle sorting/pagination in client from then-on.
         */

        if (this.data.length == 0 || this.getDataAgain) {
            this.contentService.getTableData(tableID, filter, sortOrder, page, pageSize, pageID, additionalParameters).subscribe({
                next: (v) => {
                    this.getDataAgain = false;
                    this.handleDataLoad(v);
                    this.sortAndPaginate(filter, sortOrder, page, pageSize);
                },
                error: (e) => console.error(e)
            });
        } else {
            this.sortAndPaginate(filter, sortOrder, page, pageSize);
        }
    }

    handleDataLoad(v) {
        this.data = v;
        this.dataLength = v.length;
    }

    sortAndPaginate(sortColumn = '', sortOrder = 'asc', page = 1, pageSize = 25) {
        //sort data
        let sortedData = [];
        if (sortColumn != '') {
            sortedData = this.data.sort((a, b) => {
                if (a[sortColumn] < b[sortColumn]) {
                    return sortOrder == 'asc' ? -1 : 1;
                } else if (a[sortColumn] > b[sortColumn]) {
                    return sortOrder == 'asc' ? 1 : -1;
                } else {
                    return 0;
                }
            });
        } else {
            sortedData = this.data;
        }

        let startIndex = (page - 1) * pageSize;
        let endIndex = startIndex + pageSize;
        let currentPage = sortedData.slice(startIndex, endIndex);

        this.dataSubject.next(currentPage);

        this.loadingSubject.next({
            loading: false,
            size: this.data.length
        });
    }

    filterData(columnIndex: number, filter: string) {
        let filteredData = this.data.filter(row => {
            if (row[columnIndex] != null) {
                let rowText: string = row[columnIndex].toString();
                return rowText.toLowerCase().includes(filter.toLowerCase());
            }
            return false;
        });

        this.dataSubject.next(filteredData);
    }
}
