import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { OrgInfo } from 'src/app/base/interfaces/org-info';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { WorkflowService } from '../services/workflow/workflow.service';

@Component({
  selector: 'app-header-base',
  template: ``,
  styles: []
})
export class HeaderBaseComponent implements OnInit {
    public orginfo: OrgInfo | any;

    selectedOrg: any;

    constructor(public auth: AuthService, public nav: NavService, public app: AppComponent, public workflow: WorkflowService) {}

    ngOnInit(): void {
        if (this.auth.getToken() != "" && this.auth.getToken() != null) {
            this.showOrgName();
            this.selectedOrg = this.auth.getOrg();
        }
    }
    
    showOrgName() {
        this.nav.getOrgName(this.auth.getOrg(), this.auth.getToken()).subscribe(
            (data: OrgInfo) => {
                let properDecendants = data.descendants != null ? Object.values(data.descendants) : null;
                let properSiblings = data.siblings != null ? Object.values(data.siblings) : null;
                
                this.orginfo = {
                    orgName: data.orgName,
                    name: data.name,
                    ancestor: data.ancestor,
                    descendants: properDecendants,
                    siblings: properSiblings
                };
            },
            (error) => {
                //we don't care if this doesn't load
                console.warn(error);
            }
        );
    }

    onOrgChange($event) {
        //get value from dropdown
        let selectedOrgID = $event.value;
        this.selectedOrg = selectedOrgID;

        this.auth.changeOrg(selectedOrgID).subscribe((data: any) => {
            window.location.reload();
        });
    }

}
