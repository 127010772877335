import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';

@Component({
    selector: 'app-multi-checkbox',
    templateUrl: './multi-checkbox.component.html',
    styleUrls: ['./multi-checkbox.component.css']
})
export class MultiCheckboxComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup;

    selectedIDs: number[] = [];

    constructor() { }

    ngOnInit(): void {
        if (this.field.value != null) this.selectedIDs = JSON.parse(this.field.value);
    }

    shouldBeSelected(itemID: any): boolean {
        return this.selectedIDs.indexOf(itemID) >= 0;
    }

    itemChanged(item: any, checked: any) {
        if (checked) {
            this.selectedIDs.push(item);
        } else {
            this.selectedIDs.splice(this.selectedIDs.indexOf(item), 1);
        }

        this.formGroup.get(this.fieldKey).setValue(this.selectedIDs);
    }

}
