import { Component, OnInit } from '@angular/core';
import { consoleSandbox } from '@sentry/utils';
import { AppComponent } from 'src/app/app.component';
import { HeaderBaseComponent } from 'src/app/base/components/header-base.component';
import { OrgInfo } from 'src/app/base/interfaces/org-info';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { WorkflowService } from 'src/app/base/services/workflow/workflow.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent extends HeaderBaseComponent implements OnInit {
    public orginfo: OrgInfo | any;

    selectedOrg: any;

    constructor(public auth: AuthService, public nav: NavService, public app: AppComponent, public workflow: WorkflowService) {
        super(auth, nav, app, workflow)
    }

    ngOnInit(): void {
        if (this.auth.getToken() != "" && this.auth.getToken() != null) {
            this.showOrgName();
            this.selectedOrg = this.auth.getOrg();
        }
    }

    showOrgName() {
        this.nav.getOrgName(this.auth.getOrg(), this.auth.getToken()).subscribe(
            (data: OrgInfo) => {
                let properDecendants: any[] = data.descendants != null ? Object.values(data.descendants) : null;
                let properSiblings: any[] = data.siblings != null ? Object.values(data.siblings) : null;

                if (properSiblings != null) {
                    properSiblings.sort(function (a, b) {
                        var textA = a.org_name.toUpperCase();
                        var textB = b.org_name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                }

                if (properDecendants != null) {
                    properDecendants.sort(function (a, b) {
                        var textA = a.org_name.toUpperCase();
                        var textB = b.org_name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                }
                this.orginfo = {
                    orgName: data.orgName,
                    name: data.name,
                    ancestor: data.ancestor,
                    descendants: properDecendants,
                    siblings: properSiblings
                };
            },
            (error) => {
                //we don't care if this doesn't load
                console.warn(error);
            }
        );
    }

    onOrgChange($event) {
        //get value from dropdown
        let selectedOrgID = $event.value;
        this.selectedOrg = selectedOrgID;

        this.auth.changeOrg(selectedOrgID).subscribe((data: any) => {
            window.location.reload();
        });
    }
}