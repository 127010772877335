import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { AppidService } from 'src/app/base/services/appid/appid.service';
import { WorkflowService } from 'src/app/base/services/workflow/workflow.service';
import { AppComponent } from 'src/app/app.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MediaObserver } from '@angular/flex-layout';

@Component({
    selector: 'app-auth-base',
    template: ``,
    styles: []
})

export class LoginBaseComponent implements OnInit {
    loginForm = this.fb.group({});

    selectedRole = 0;
    loginInProgress = false;

    get fields() {
        return this.loginForm.get('fields') as UntypedFormArray;
    }

    constructor(
        public fb: UntypedFormBuilder,
        public snackBar: MatSnackBar,
        public router: Router,
        public auth: AuthService,
        public nav: NavService,
        public activatedRoute: ActivatedRoute,
        public workflow: WorkflowService,
        public app: AppComponent,
        public appID: AppidService) { }


    ngOnInit() {
        this.auth.logout();

        // load EZS fake 15
        if (localStorage.getItem("routes") == null) {
            this.nav.clearNavs().subscribe(
                (data) => {
                    console.log("Navs cleared");
                },
            );
        }

        this.loginForm.addControl("Username", this.fb.control(''));
        this.loginForm.addControl("Password", this.fb.control(''));

        this.activatedRoute.queryParams.subscribe(params => {
            //if ?role parameter is set to 97, make the venue login button active
            if (params['role'] == 97) this.selectedRole = 97;
            else if (params['role'] == 98) this.selectedRole = 98;

            if (params["msg"]) {
                this.snackBar.open(params["msg"], '', {
                });
            }
        });
    }

    onSubmit() {
        if (this.selectedRole == 0) {
            this.snackBar.open('To login, you first choose a role.', '', {
                duration: 2000
            });
            return;
        }

        if (!this.loginInProgress && this.loginForm.valid) {
            this.loginInProgress = true;
            this.auth.login(this.loginForm.get('Username').value, this.loginForm.get('Password').value, this.selectedRole).subscribe(
                async (data) => {
                    let roleID = data["role"];
                    let targetNav = data["nav"];
                    let targetPage = data["page"];
                    let targetMsg = data["msg"];

                    //283 or 287 should also use workflowservice to get which page to go to
                    if (roleID == 283 || roleID == 287) {
                        let workflowData = await this.workflow.initialLogin().toPromise();
                        targetNav = workflowData["nav"];
                    }

                    this.nav.updateNavs(data['token'], data['role']).subscribe(
                        (routeLoaded) => {
                            /**
                             * update 11/17/22: the logic here has been modified to support sending to people
                             * to a specific page, even with a submit message! This was a requirement to support
                             * non-required registration steps.
                             * 
                             * If a target page is specified, the nav cache is searched to see if the page is associated
                             * with a nav. If it is, we sent them to that nav. If it is not, we send them to the nav
                             * that was included in the request and attach the page as a ?page query parameter.
                             * 
                             * In both cases, if a submit message is specified, that is attached as another query parameter
                             * with the ?msg key, and that will be displayed on whatever page the user is sent to.
                             */

                            let url = null;
                            if (targetPage) {
                                url = this.router.config.find(route => {
                                    if (route.data && route.data.hasPage) {
                                        return route.data.navPage == targetPage;
                                    }
                                    return false;
                                });

                                if (url) url = url.path;

                                if (targetMsg && url) {
                                    url += "?msg=" + targetMsg;
                                }
                            }

                            if (!url) {
                                url = this.router.config.find(route => route.data.nav === targetNav).path;

                                if (targetPage) {
                                    url = url + "?page=" + targetPage;

                                    if (targetMsg) {
                                        url = url + "&msg=" + targetMsg;
                                    }
                                }
                            }

                            this.loginInProgress = false;
                            this.router.navigateByUrl(url);
                        },
                        (error) => { }
                    );
                },
                (error) => {
                    this.snackBar.open('Login failed: ' + error["error"] + '. Make sure you have selected the correct role.', '', {
                        duration: 5000
                    });
                    this.loginInProgress = false;
                }
            );
        }
    }

    switchRole(newlySelectedRole: any): void {
        this.selectedRole = newlySelectedRole;
    }
    /**
     * update 8/21, per GIG-T89, Cyril wants to go to the registration page
     * based upon the currently active button on the login page
     */
    getSignupLink(): any {
        if (this.selectedRole == 97) return "/auth/register/venue";
        else if (this.selectedRole == 98) return "/auth/register/performer";
    }

    referSignUpLink(): any {
        if (this.selectedRole == 0) return this.snackBar.open('To sign up, you first choose a role.', '', {
            duration: 5000
        });
    }

    referForgotPasswordLink(): any {
        if (this.selectedRole == 0) return this.snackBar.open('To reset your password, you first choose a role.', '', {
            duration: 5000
        });
    }

    getForgotPasswordLink(): string {
        if (this.selectedRole == 97) return "/auth/forgot-password/venue";
        else return "/auth/forgot-password/performer"
    }

}

