import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';
import { FormComponent } from '../../form/form.component';
@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.css']
})
export class DatepickerComponent {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: FormGroup;

    constructor(public form: FormComponent) { }

}
