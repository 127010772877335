import { Component, OnInit } from '@angular/core';
import { TextFieldBaseComponent } from 'src/app/base/components/fields/textfield-base.component';

@Component({
  selector: '[app-easysuite-textfield]',
  templateUrl: './easysuite-textfield.component.html',
  styleUrls: ['./easysuite-textfield.component.css']
})
export class TextfieldComponent extends TextFieldBaseComponent implements OnInit {

  constructor() {super()}

  ngOnInit(): void {
  }

}
