<ng-template #rootItem let-item>
    <li>
        <div class="checkboxItem">
            <mat-checkbox [class.selected]="shouldBeSelected(item)" [checked]="shouldBeSelected(item)" [indeterminate]="someComplete(item) && !allComplete(item)" (change)="itemChanged(item, $event.checked)">
                {{item.name}}
            </mat-checkbox>

            <!--Master Expand/ Collapse Arrow  -->
            <span *ngIf="hasChildren(item)" (click)="expandCollapse(item)">
                <mat-icon aria-hidden="false" aria-label="Example home icon" *ngIf="item.collapsed;else isCollapsed">arrow_right</mat-icon>
                <ng-template #isCollapsed>
                    <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_drop_down</mat-icon>
                </ng-template>
            </span>
        </div>

        <ng-container *ngIf="hasChildren(item) && !item.collapsed">
            <ul>
                <ng-container *ngFor="let itemChild of item.children">
                    <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: itemChild}"></ng-container>
                </ng-container>
            </ul>
        </ng-container>
    </li>
</ng-template>

<p>{{field.label}}:</p>
<section class="example-section" [formGroup]="this.formGroup">
    <ul>
        <ng-container *ngFor="let item of choices">
            <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: item}"></ng-container>
        </ng-container>
    </ul>
</section>