import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class TextfieldService {

    private sharedDataSubject = new Subject<any>();

    setSharedData(data: any) {
        this.sharedDataSubject.next(data);
    }

    getSharedData() {
        return this.sharedDataSubject.asObservable();
    }

}
