import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Field } from '../../interfaces/field';
import { ContentService } from '../../services/content/content.service';

@Component({
  selector: 'app-upload-base',
  template: ``,
  styles: []
})
export class UploadBaseComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup

    fileName: string;
    uploadedFile: string;

    constructor(public content: ContentService) { }

    ngOnInit(): void {
        if (this.field.value != null && this.field.value != "") {
            let envURL = environment.urls.core;
            this.uploadedFile = envURL + this.field.value;
        }
    }

    onFileSelected(event: any) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileName = "Selected: " + file.name;

            var reader = new FileReader();

            reader.onload = (e) => {
                this.formGroup.get(this.fieldKey).setValue({
                    fileName: file.name,
                    base64: reader.result,
                    fileType: file.type
                });
            };
            
            reader.readAsDataURL(file);
        }
    }
}
