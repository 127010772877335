import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from "../material/material.module";
import { GlobalSharedModule } from '../global-shared/global-shared.module';
import { BaseModule } from '../base/base.module';

import { LoginComponent } from './components/login/login.component';
import { SuperstructureComponent } from './components/superstructure/superstructure.component';
import { TableComponent } from './components/table/table.component';
import { FormComponent } from './components/form/form.component';
import { TextfieldComponent } from './components/fields/textfield/textfield.component';
import { RadioComponent } from './components/fields/radio/radio.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { MultiCheckboxComponent } from './components/fields/multi-checkbox/multi-checkbox.component';
import { MultilevelCheckboxComponent } from './components/fields/multilevel-checkbox/multilevel-checkbox.component';
import { UploadComponent } from './components/fields/upload/upload.component';
import { DatepickerComponent } from './components/fields/datepicker/datepicker.component';
import { HeaderComponent } from './components/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { TextareaComponent } from './components/fields/textarea/textarea.component';
import { DropdownComponent } from './components/fields/dropdown/dropdown.component';
import { PopupComponent } from './components/popup/popup.component';
import { MultilevelCheckboxNoselectComponent } from './components/fields/multilevel-checkbox-noselect/multilevel-checkbox-noselect.component';
import { EmailConfirmationComponent } from './components/fields/email-confirmation/email-confirmation.component';
import { TimepickerComponent } from './components/fields/timepicker/timepicker.component';
import { PasswordConfirmationComponent } from './components/fields/password-confirmation/password-confirmation.component';
import { PasswordComponent } from './components/fields/password/password.component';
import { AreyousureComponent } from './components/areyousure/areyousure.component';
import { TextDollarsComponent } from './components/fields/text-dollars/text-dollars.component';
import { CheckboxComponent } from './components/fields/checkbox/checkbox.component';
import { MobileNavComponent } from './components/mobile-nav/mobile-nav.component';
import { InvalidPopupComponent } from './components/invalid-popup/invalid-popup.component';

@NgModule({
  declarations: [
    LoginComponent,
    SuperstructureComponent,
    TableComponent,
    FormComponent,
    TextfieldComponent,
    RadioComponent,
    TopNavComponent,
    MultiCheckboxComponent,
    MultilevelCheckboxComponent,
    UploadComponent,
    DatepickerComponent,
    HeaderComponent,
    SidenavComponent,
    TextareaComponent,
    DropdownComponent,
    PopupComponent,
    MultilevelCheckboxNoselectComponent,
    EmailConfirmationComponent,
    TimepickerComponent,
    PasswordConfirmationComponent,
    PasswordComponent,
    AreyousureComponent,
    TextDollarsComponent,
    CheckboxComponent,
    MobileNavComponent,
    InvalidPopupComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    GlobalSharedModule,
    BaseModule
  ],
  exports: [
    LoginComponent,
  ]
})
export class GigmonsterModule { }
