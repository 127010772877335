import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { AppidService } from '../appid/appid.service';
import { ContentService } from '../content/content.service';

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {

    constructor(private http: HttpClient, private auth: AuthService, private content: ContentService, private appid: AppidService) { }

    isInWorkFlow(): boolean {
        let storageResult = sessionStorage.getItem("isInWorkflow");
        if (storageResult == null || storageResult == "false") return false;
        else return true;
    }

    disableWorkflow(): void {
        sessionStorage.setItem('isInWorkflow', 'false');
    }

    initialLogin(): any {
        let httpParams = new HttpParams().set('app_id', this.appid.appID);
        httpParams = httpParams.set('org_id', this.auth.getOrg());
        httpParams = httpParams.set('role_id', this.auth.getRole());

        sessionStorage.setItem('isInWorkflow', 'true');

        return this.http.get<any>(environment.urls.core + "/workflow/getCurrentNavRegStep", {
            params: httpParams,
            headers: {
                'authorization': this.auth.getToken()
            }
        });
    }

    getMessage(pageID): any {
        let httpParams = new HttpParams().set('app_id', this.appid.appID);
        httpParams = httpParams.set('org_id', this.auth.getOrg());
        httpParams = httpParams.set('page_id', pageID);

        return this.http.get<any>(environment.urls.core + "/workflow/message", {
            params: httpParams,
            headers: {
                'authorization': this.auth.getToken()
            }
        });
    }

    completeStep(pageID): Observable<any> {
        let httpParams = new HttpParams().set('app_id', this.appid.appID);
        httpParams = httpParams.set('org_id', this.auth.getOrg());
        httpParams = httpParams.set('page_id', pageID);

        return this.http.get<any>(environment.urls.core + "/workflow/complete", {
            params: httpParams,
            headers: {
                'authorization': this.auth.getToken()
            }
        });
    }

    skipStep(pageID): Observable<any> {
        let httpParams = new HttpParams().set('app_id', this.appid.appID);
        httpParams = httpParams.set('org_id', this.auth.getOrg());
        httpParams = httpParams.set('page_id', pageID);

        return this.http.get<any>(environment.urls.core + "/workflow/skip", {
            params: httpParams,
            headers: {
                'authorization': this.auth.getToken()
            }
        });
    }
}
