<div id="header" style="background:#fff;max-height:152px">
    <div class="row">
        <div class="col-md-12" style="display: flex;">
            <div class="logo_left" style="display:table">
                <div style="display:table-cell;vertical-align:middle;">
                    <img id="logo_left" src="https://app.lotterease.com/images/lottereaselogo.png" style=" max-height: 52px;" />
                </div>
            </div>
            <div style="display:table;" class="advertisement">
                <div class='content_advertisement' style="text-align:center;font-size:30px;height:auto;display:table-cell;">
                    <a href="" target="_blank">
                        <img id="img_advertisement" src="">
                    </a>
                </div>
            </div>
            <div style="display:table;" class="logo_right">
                <div class="logo_org" style="display:table-cell;vertical-align:middle;padding-right:0 !important;padding-top:0;text-align:right">
                    <img src="https://rad.frog10.improvation.us/services/media/logo/7.jpg" id="logo_right" />
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" style="margin-top:5px;margin-bottom:5px">
    <div class="col-md-12 ">
        <div class="mobile_small1">
            <select class="app_dropdown" onchange="fChangeApp(this.value)">
                <option value="">- Select -</option>
                <option value='795418' selected>Lotterease</option>
                <option value='985135'>Foodease</option>
                <option value='985143'>Journease</option>
                <option value='985146'>Enrollease</option>
                <option value='985149'>VaxUp</option>
                <option value='434693'>GigMonster</option>
                <option value='777846'>I Am Growth</option>
                <option value='985151'>Supervisease</option>
                <option value='985152'>Test 123</option>
                <option value='165112'>Purchasease</option>
            </select>
        </div>
    </div>
</div>