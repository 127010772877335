<div class="form">
    <div class="fields">
        <ng-container *ngFor="let field of form.fields | keyvalue: asIsOrder">
            <app-radio *ngIf="field.value.type === 'radio'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-radio>
            <app-timepicker *ngIf="field.value.type === 'time picker'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-timepicker>
            <app-datepicker *ngIf="field.value.type === 'date picker'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-datepicker>
            <app-textfield *ngIf="field.value.type === 'text'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-textfield>
            <app-multilevel-checkbox *ngIf="field.value.type === 'multilevel-checkbox'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></app-multilevel-checkbox>
            <app-multi-checkbox *ngIf="field.value.type == 'multi-checkbox'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></app-multi-checkbox>
            <app-textarea *ngIf="field.value.type === 'textarea'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-textarea>
            <app-dropdown *ngIf="field.value.type === 'dropdown'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-dropdown>
            <app-text-dollars *ngIf="field.value.type === 'text dollars'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-text-dollars>
            <app-checkbox *ngIf="field.value.type === 'checkbox'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-checkbox>
            <app-upload *ngIf="field.value.type === 'file'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-upload>
            <app-email-confirmation *ngIf="field.value.type === 'Email with confirmation'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></app-email-confirmation>
            <app-password-confirmation *ngIf="field.value.type === 'Password with confirmation'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></app-password-confirmation>
            <app-password *ngIf="field.value.type === 'password'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-password>
            <app-multilevel-checkbox-noselect *ngIf="field.value.type === 'multilevel-checkbox-noselect'"
                [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-multilevel-checkbox-noselect>
        </ng-container>
    </div>
    <div class="formButtons">
        <button *ngIf="this.form.showSubmitButton && this.showSubmitButton" mat-raised-button color="primary"
            (click)="submitForm()">Submit</button>
        <button *ngIf="this.form.showCancelButton" mat-raised-button color="secondary"
            (click)="cancelForm()">Cancel</button>
    </div>
</div>