<ng-template #rootItem let-item>
    <a class="sidenav-link" (click)="navigateHandler(item.link)" [class.selected]="isActive(item.link)">{{item.label |
        uppercase}}
        <span *ngIf="hasChildren(item)" (click)="expandOrCollapse(item)">
            <mat-icon aria-hidden="false" aria-label="Example home icon"
                *ngIf="item.collapsed;else isItemCollapsed">arrow_right</mat-icon>
            <ng-template #isItemCollapsed>
                <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_drop_down</mat-icon>
            </ng-template>
        </span>

    </a>
    <ng-container *ngIf="hasChildren(item) && !item.collapsed">
        <div class="children">
            <ng-container *ngFor="let itemChild of item.children">
                <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: itemChild}"></ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-template>




<nav class="sidenav">
    <ng-container *ngFor="let item of data">
        <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: item}"></ng-container>
    </ng-container>
</nav>