import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { NavService } from './base/services/nav/nav.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    public innerWidth: any;

    constructor(private nav: NavService) { }

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;
        let result = this.nav.loadNavsByCache();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    ngOnDestroy(): void {

    }
}
