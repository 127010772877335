import { Component, Input, OnInit } from '@angular/core';
import { SidenavBaseComponent } from 'src/app/base/components/sidenav-base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AppidService } from 'src/app/base/services/appid/appid.service';
import { AppComponent } from 'src/app/app.component';
import { PsuedoLoginPageInformation } from '../easysuite-superstructure/easysuite-superstructure.component';

@Component({
    selector: 'app-easysuite-side-nav',
    templateUrl: './easysuite-side-nav.component.html',
    styleUrls: ['./easysuite-side-nav.component.css']
})
export class EasysuiteSideNavComponent extends SidenavBaseComponent implements OnInit {

    @Input() psuedoLoginInfo: PsuedoLoginPageInformation = null;

    constructor(
        public activatedRoute: ActivatedRoute,
        public router: Router,
        public app: AppComponent,
        public AppId: AppidService) {
        super(activatedRoute, router, app, AppId)
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

}
