import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from '../../interfaces/field';

@Component({
  selector: 'app-checkbox-base',
  template: ``,
  styles: []
})
export class CheckBoxBaseComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup;

    constructor() { }

    ngOnInit(): void {
    }

    shouldBeChecked(): boolean {
        return this.formGroup.get(this.fieldKey).value == true;
    }

    click(checked) {
        this.formGroup.get(this.fieldKey).setValue(checked);
    }

}
