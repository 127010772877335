import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css']
})
export class TextareaComponent implements OnInit {
  @Input() field: Field;
  @Input() fieldKey: string;
  @Input() formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
    let format = this.field.format;
    //date, time, price, number, phone number, email
  }

}
