<ng-template #rootItem let-item>
    <li class="has-sub show_parent" [class.active]="isActive(item.link)" style="cursor: grab;">
        <a (click)="navigate(item.link)">{{item.label |uppercase}}</a>

        <span class="submenu-button1" *ngIf="hasChildren(item)" (click)="expandOrCollapse(item)" [class.open]="item.collapsed == true"></span>
    </li>
    <ng-container *ngIf="item.collapsed == false">
        <ng-container *ngFor="let itemChild of item.children">
            <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: itemChild}">
            </ng-container>
        </ng-container>
    </ng-container>
    <!-- Master Expand/ Collapse Arrow
    <span *ngIf="hasChildren(item)" (click)="expandOrCollapse(item)">
        <mat-icon aria-hidden="false" aria-label="Example home icon"
            *ngIf="item.collapsed;else isItemCollapsed">arrow_right</mat-icon>
        <ng-template #isItemCollapsed>
            <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_drop_down</mat-icon>
        </ng-template>
    </span> -->
</ng-template>

<div class="sidebarmenu">
    <ul class="sidebar_menu">
        <ng-container *ngIf="this.psuedoLoginInfo == null">
            <ng-container *ngFor="let item of data">
                <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: item}"></ng-container>
            </ng-container>

            <!-- registration role stuff -->
            <ng-container *ngIf="this.isRegMode">
                <a class="" [class.selected]="isActive">REGISTRATION</a>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="this.psuedoLoginInfo != null">
            <!-- this is utilized for login page stuff -->
            <ng-container *ngFor="let item of this.psuedoLoginInfo.LoginNavs | keyvalue">
                <li class="level0">
                    <a style="cursor: pointer;">{{item.value.label | uppercase}}</a>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</div>