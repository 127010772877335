import { ChangeDetectorRef, Component, Injectable, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AppidService } from '../services/appid/appid.service';

@Component({
    selector: 'app-sidenav-base',
    template: ``,
    styles: []
})

@Injectable({
    providedIn: 'root'
})

export class SidenavBaseComponent implements OnInit {
    data: any = null;

    @Input("isRegMode") isRegMode: boolean = false;

    constructor(
        public activatedRoute: ActivatedRoute,
        public router: Router,
        public app: AppComponent,
        public AppId: AppidService) { }


    ngOnInit(): void {
        this.data = [];

        this.activatedRoute.url.subscribe(url => {
            this.data = [];

            //combine url array into string
            let urlString = url[0].path; //basepath

            //filter this.router.config to get all routes that begin with the urlString
            let filteredRoutes = this.router.config.filter(x => x.path.split("/")[0] == urlString && x.path.split("/").length > 1);

            this.data.push(...this.buildNavArray(filteredRoutes, "", 0));

            //set all ancestor items to expanded
            //find the currently selected item in data
            let index = 2;
            let parent: any = this.data.find(x => x.link.split("/")[index] == this.router.url.split("/")[index]);
            while (parent != null) {
                index++;
                parent.collapsed = false;
                parent = parent.children.find(y => y.originalLink.split("/")[index] == this.router.url.split("/")[index]);
            }
        });

        //recursively sort this.data by sortOrder
        this.data.sort(this.asIsOrder);

        this.data.forEach(element => {
            if (element.children != null) {
                element.children.sort(this.asIsOrder);
                element.children.forEach(child => {
                    if (child.children != null) {
                        child.children.sort(this.asIsOrder);
                    }
                })
            }
        });
    }

    buildNavArray(routes: Route[], basepath: string, level: number): any {
        let newItems = [];

        let thisLevelRoutes = routes.filter(x => {
            let validPath = true;
            if (basepath != "") {
                validPath = x.path.indexOf(basepath) == 0;
            }
            return x.path.split("/").length == level + 2 && validPath
        });

        thisLevelRoutes.forEach(item => {
            let itemChildren = this.buildNavArray(routes, item.path + "/", level + 1);
            itemChildren.sort(this.asIsOrder);

            let link = "/" + item.path;
            let originalLink = link;
            if (!item.data.hasPage && itemChildren.length > 0 && itemChildren[0].hasPage) {
                link = itemChildren[0].link;
            }

            let newItem = {
                label: item.data.label,
                link: link,
                children: itemChildren,
                hasPage: item.data.hasPage,
                collapsed: true,
                originalLink: originalLink,
                sortOrder: item.data.sortOrder
            };

            newItems.push(newItem);
        });

        return newItems;
    }

    hasChildren(data: any): boolean {
        return (data.children.length > 0);
    }

    expandOrCollapse(item: any): void {
        item.collapsed = !item.collapsed;
    }

    navigate(link): void {
        //navigate and clear all query params
        //get all query params and set to null
        let queryParams = this.router.parseUrl(this.router.url).queryParams;
        for (let key in queryParams) {
            queryParams[key] = null;
        }

        this.router.navigate(["/" + link], {
            queryParams: queryParams,
            queryParamsHandling: "merge"
        })
    }

    asIsOrder(a, b) {
        return Number.parseInt(a.sortOrder) > Number.parseInt(b.sortOrder) ? 1 : -1;
    }

    //determine if this top nav is active
    isActive(link): boolean {
        //check if this.router.url minus any http parameters matches the link
        let url = this.router.url.split("?")[0];
        return url == link;
    }

}
