<div [formGroup]="this.formGroup">
    <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>{{field.label}}</mat-label>
        <input type="password" matInput placeholder="{{field.label}}" [formControlName]="this.fieldKey">
        <!--<mat-hint>Hint</mat-hint>-->
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Confirm {{field.label}}</mat-label>
        <input type="password" matInput placeholder="Confirm {{field.label}}" [formControlName]="this.fieldKey">
        <!--<mat-hint>Hint</mat-hint>-->
    </mat-form-field>
</div>