<div class="dropdown" [formGroup]="this.formGroup">
    <td valign="middle" class="label_field_906" style="width: 323.062px;">{{field.label}}</td>
    <td valign="middle">
        <label style="font-weight: 500;">{{field.label}}</label>
        <select name="field_id_906" class="field_submit" [formControlName]="this.fieldKey">
            <option *ngFor="let choice of field.choices | keyvalue: originalOrder" [value]="choice.key">
                <span *ngIf="choice.value.value">{{choice.value.value}}</span>
                <span *ngIf="!choice.value.value">{{choice.value}}</span>
            </option>
        </select>
    </td>
</div>