<div class="main"
    [class.invalid]="!this.formGroup.controls[this.fieldKey].valid && this.formGroup.controls[this.fieldKey].touched">
    <p style="margin-right: 10px; margin-bottom: 0px;" style="font-weight: 500;">{{field.label}}:{{this.field.required ?
        " *": ''}}</p>
    <div class="checkboxes">
        <mat-checkbox *ngFor="let item of field.choices | keyvalue" [checked]="shouldBeSelected(item.key)"
            [class.selected]='shouldBeSelected(item)' (change)="itemChanged(item.key, $event.checked)" color="primary">
            <span *ngIf="item.value.value" class="text">{{item.value.value}}</span>
            <span *ngIf="!item.value.value" class="text">{{item.value}}</span>
        </mat-checkbox>
    </div>
</div>