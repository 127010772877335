import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { AppidService } from 'src/app/base/services/appid/appid.service';
import { WorkflowService } from 'src/app/base/services/workflow/workflow.service';
import { AppComponent } from 'src/app/app.component';
import { LoginBaseComponent } from 'src/app/base/components/auth-base.component';
import { PsuedoLoginPageInformation } from '../easysuite-superstructure/easysuite-superstructure.component';

@Component({
    selector: 'app-easysuite-login',
    templateUrl: './easysuite-login.component.html',
    styleUrls: ['./easysuite-login.component.css']
})
export class EasysuiteLoginComponent extends LoginBaseComponent implements OnInit {

    loginPageInfo: PsuedoLoginPageInformation = null;
    constructor(
        public fb: UntypedFormBuilder,
        public snackBar: MatSnackBar,
        public router: Router,
        public auth: AuthService,
        public nav: NavService,
        public activatedRoute: ActivatedRoute,
        public workflow: WorkflowService,
        public app: AppComponent,
        public appID: AppidService) {
        super(fb, snackBar, router, auth, nav, activatedRoute, workflow, app, appID);
    }

    ngOnInit(): void {
        super.ngOnInit();

    }

    superstructureSubmitted(values) {
        this.loginForm.get('Username').setValue(values["1"]);
        this.loginForm.get('Password').setValue(values["11"]);

        this.onSubmit();
    }

}
