import { Directive, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Directive({
    selector: '[hrefToRouterLink]'
})
export class HrefToRouterLinkDirective {
    constructor(private router: Router, private route: ActivatedRoute) {
    }

    @HostListener('click', ['$event'])
    onClick(event) {
        const clickedElement = event.target;
        if (this.canUseRouterLink(clickedElement)) {

            this.cancelClick(event);
            // To use router.navigate(), we remove hostname
            let localHref = this.getLocalUrl(clickedElement.href);

            if (this.isNumber(localHref)) {
                this.router.navigate(
                    [], 
                    {
                      relativeTo: this.route,
                      queryParams: { page: localHref },
                      queryParamsHandling: 'merge'
                    });
            } else if (this.containsOnlyParameters(localHref)) {
                //convert all query params to object
                let queryParams = {};
                let queryParamsArray = localHref.split('?')[1].split('&');
                for (let i = 0; i < queryParamsArray.length; i++) {
                    let queryParam = queryParamsArray[i].split('=');
                    queryParams[queryParam[0]] = queryParam[1];
                }

                this.router.navigate(
                    [], 
                    {
                      relativeTo: this.route,
                      queryParams: queryParams,
                      queryParamsHandling: 'merge'
                    });
            } else {
                this.router.navigate([localHref]);
            }
        }
    }

    // Identify whether element can be treated as routerLink 
    private canUseRouterLink(element): boolean {

        // We can't routerLink to external sites.
        // Using a class on local links is a good way
        // to identify links we want to use routerLink on
        // Continuing the social media example, we'll use .tag
        const localAnchorClass = 'internalLink';

        // If element has an href and our .tag class
        return !!element.href && element.classList.contains(localAnchorClass);
    }

    // Stop the browser's default behavior (navigation)
    private cancelClick(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    // Href will look like: "blog.kevynjaremko.com/some-slug"
    // Output will look like: "/some-slug"
    private getLocalUrl(href: string): string {
        let domain = window.location.host;
        href = href.replace("https://" + domain + "/", "");
        href = href.replace("http://" + domain + "/", "");
        return href;
    }

    private isNumber(value: string | number): boolean {
        return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
    }

    private containsOnlyParameters(value: string): boolean {
        return value.indexOf('?') > -1;
    }
}