import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from '../../interfaces/field';

@Component({
  selector: 'app-multicheckbox-base',
  template: ``,
  styles: []
})
export class MultiCheckBoxBaseComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup;

    selectedIDs: number[] = [];

    constructor() { }

    ngOnInit(): void {
        if (this.field.value != null) this.selectedIDs = JSON.parse(this.field.value);
    }

    shouldBeSelected(itemID: any): boolean {
        return this.selectedIDs.indexOf(itemID) >= 0;
    }

    itemChanged(item: any, checked: any) {
        if (checked) {
            this.selectedIDs.push(item);
        } else {
            this.selectedIDs.splice(this.selectedIDs.indexOf(item), 1);
        }

        this.formGroup.get(this.fieldKey).setValue(this.selectedIDs);
    }
}
