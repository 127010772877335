import { Component, Input, OnInit } from '@angular/core';
import { Field } from 'src/app/base/interfaces/field';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.css']
})
export class EmailConfirmationComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() field: Field;
  @Input() fieldKey: string;
  confirmFieldKey: string;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    
    this.confirmFieldKey = this.fieldKey + '-confirm';

    if (!this.formGroup.get(this.confirmFieldKey)) {
      this.formGroup.addControl(this.confirmFieldKey, this.fb.control('', Validators.required));
    }

    // Set email validator if the format is email
    if (this.field.format && this.field.format === 'email') {
      this.formGroup.get(this.fieldKey)?.setValidators([Validators.email, Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
      this.formGroup.get(this.confirmFieldKey)?.setValidators([Validators.email, Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
    }

    // Trigger validation on both the main field and confirmation field. This updates the form controls' values and checks their validity to ensure they reflect the most current state.
    this.formGroup.get(this.fieldKey)?.updateValueAndValidity();
    this.formGroup.get(this.confirmFieldKey)?.updateValueAndValidity();

    // Apply the custom validator to the form group
    this.formGroup.setValidators(this.emailMatchValidator(this.fieldKey, this.confirmFieldKey));
  }

  /**
   * Custom validator function to check if email and confirm email fields match.
   * @param emailKey Key of the email field in the form group.
   * @param confirmEmailKey Key of the confirm email field in the form group.
   * @returns ValidationErrors object if validation fails, otherwise null.
   */
  emailMatchValidator(emailKey: string, confirmEmailKey: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const email = formGroup.get(emailKey)?.value;
      const confirmEmail = formGroup.get(confirmEmailKey)?.value;


      if (email !== confirmEmail) {
        this.formGroup.controls[confirmEmailKey].setErrors({'invalid': true});
        this.formGroup.controls[emailKey].setErrors({'invalid': true});
        
        return { emailMismatch: true };
      }else{ 
        this.formGroup.controls[confirmEmailKey].setErrors(null);
        this.formGroup.controls[emailKey].setErrors(null);
    }
      return null;
    };
  }

  /**
   * Checks if a specific field in the form group is invalid and has been touched or dirty.
   * @param field Key of the field in the form group to check.
   * @returns True if the field is invalid and touched or dirty, otherwise false.
   */
  isFieldInvalid(field: string): boolean {
    const control = this.formGroup.get(field);
    const isInvalid = control ? control.invalid && (control.touched || control.dirty) : false;
    const hasMismatch = this.formGroup.hasError('emailMismatch');
    return isInvalid || hasMismatch;
  }

}
