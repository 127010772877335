import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from '../../interfaces/field';

export interface Choice {
    name: string;
    id: number;
    collapsed: boolean;
    children?: Choice[];
}

@Component({
  selector: 'app-multilevelcheckbox-base',
  template: ``,
  styles: []
})


export class MultiLevelCheckBoxBaseComponent implements OnInit {
    @Input() public field: Field;
    @Input() public fieldKey: string;
    @Input() public formGroup: UntypedFormGroup;

    choices: Choice[] = [];
    selectedIDs: number[] = [];

    ngOnInit(): void {
        if (this.formGroup.get(this.fieldKey).value != null) {
            let valuesToMap = this.formGroup.get(this.fieldKey).value;
            valuesToMap.forEach(x => {
                this.selectedIDs.push(x.ID);
            });
        }

        for (var choiceKey in this.field.choices) {
            let choice = this.field.choices[choiceKey];
            this.choices.push({
                name: choice.name,
                id: +choiceKey,
                collapsed: true,
                children: this.recurseChildren(choice)
            })
        }
    }

    recurseChildren(rawData: any): Choice[] {
        let choiceChildren: Choice[] = [];
        for (var childKey in rawData.children) {
            let child = rawData.children[childKey];

            var childChoice: Choice = {
                name: child.name,
                id: +childKey,
                collapsed: true,
                children: this.recurseChildren(child)
            };

            choiceChildren.push(childChoice);
        }

        return choiceChildren;
    }

    expandCollapse(item: Choice) {
        item.collapsed = !item.collapsed;
    }

    hasChildren(data: Choice): boolean {
        if (data.children != null) return (data.children.length > 0);
        else return false;
    }

    updateAllComplete() {

    }

    someComplete(item: Choice): boolean {
        if (item.children != null) {
            if (item.children.some(x => {
                return this.selectedIDs.indexOf(x.id) >= 0;
            })) {
                return true;
            }
        }
        return false;
    }

    allComplete(item: Choice): boolean {
        if (item.children != null) {
            if (item.children.every(x => {
                return this.selectedIDs.indexOf(x.id) >= 0;
            })) {
                return true;
            }
        }
        return false;
    }

    shouldBeSelected(item: Choice): boolean {
        return this.selectedIDs.indexOf(item.id) >= 0;
    }

    itemChanged(item: Choice, checked: any) {
        if (checked) {
            this.selectedIDs.push(item.id);
            if (item.children != null) {
                item.children.forEach(x => {
                    if (!this.selectedIDs.includes(x.id)) this.selectedIDs.push(x.id);
                });
            }
        } else {
            this.selectedIDs.splice(this.selectedIDs.indexOf(item.id), 1);
            if (item.children != null) {
                item.children.forEach(x => {
                    this.selectedIDs.splice(this.selectedIDs.indexOf(x.id), 1);
                });
            }
        }

        this.formGroup.get(this.fieldKey).setValue(this.selectedIDs);
    }
}
