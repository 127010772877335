import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';
import { RadioComponent } from 'src/app/gigmonster/components/fields/radio/radio.component';

@Component({
  selector: '[app-easysuite-radio]',
  templateUrl: './easysuite-radio.component.html',
  styleUrls: ['./easysuite-radio.component.css']
})
export class EasysuiteRadioComponent extends RadioComponent {

constructor() { super()}

}
