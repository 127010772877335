import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { NavService } from 'src/app/base/services/nav/nav.service';

@Component({
    selector: 'app-mobile-nav-base',
    template: ``,
    styles: []
})

export class MobileNavBaseComponent implements OnInit {
    @Input("isRegMode") isRegMode: boolean = false;
    @Input("regRole") regRole: string = null;

    @Output("collapseMobileNav") collapse = new EventEmitter();

    data: any[] = [];

    constructor(
        public router: Router,
        public navService: NavService,
        public authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.data = [];

        this.data.push(...this.buildNavArray(this.router.config, "", 1));

        //set all ancestor items to expanded
        //find the currently selected item in data
        let index = 1;
        let parent: any = this.data.find(x => x.link.split("/")[index] == this.router.url.split("/")[index]);
        while (parent != null) {
            index++;
            parent.collapsed = false;
            parent = parent.children.find(y => y.originalLink.split("/")[index] == this.router.url.split("/")[index]);
        }

        //recursively sort this.data by sortOrder
        this.data.sort(this.asIsOrder);

        this.data.forEach(element => {
            if (element.children != null) {
                element.children.sort(this.asIsOrder);
                element.children.forEach(child => {
                    if (child.children != null) {
                        child.children.sort(this.asIsOrder);
                    }
                })
            }
        });
    }

    buildNavArray(routes: Route[], basepath: string, level: number): any {
        let newItems = [];

        //find all routes at level level
        let thisLevelRoutes = routes.filter(x => x.path.split("/").length == level);

        //if basepath is not empty and we're looking for sublevels,
        //only search for routes that start with basepath
        if (basepath != "" && level > 1) {
            thisLevelRoutes = thisLevelRoutes.filter(x => x.path.startsWith(basepath));
        }

        thisLevelRoutes.forEach(item => {
            if (!item.data) {
                return;
            }

            let itemChildren = this.buildNavArray(routes, item.path + "/", level + 1);
            itemChildren.sort(this.asIsOrder);

            let link = "/" + item.path;
            let originalLink = link;

            //if this item does not have a page, recursively find the first descendant item that does
            if (item.data && !item.data.hasPage) {
                for (let index = 0; index < itemChildren.length; index++) {
                    const element = itemChildren[index];
                    if (element.hasPage) {
                        link = element.link;
                        break;
                    } else {
                        if (element.children != null) {
                            for (let nextIndex = 0; nextIndex < element.children.length; nextIndex++) {
                                const child = element.children[nextIndex];
                                if (child.hasPage) {
                                    link = child.link;
                                    break;
                                }
                            }
                            if (link != originalLink) {
                                break;
                            }
                        }
                    }
                }
            }

            let newItem = {
                label: item.data.label,
                link: link,
                children: itemChildren,
                hasPage: item.data.hasPage,
                collapsed: true,
                originalLink: originalLink,
                sortOrder: item.data.sortOrder
            };

            newItems.push(newItem);
        });

        return newItems;
    }

    hasChildren(data: any): boolean {
        return (data.children.length > 0);
    }

    expandOrCollapse(item: any): any {
        if (item.collapsed == true) {
            item.collapsed = false;
        } else {
            item.collapsed = true;
        }
    }

    navigate(link): void {
        this.collapseMobileNav();

        //navigate and clear all query params
        //get all query params and set to null
        let queryParams = this.router.parseUrl(this.router.url).queryParams;
        for (let key in queryParams) {
            queryParams[key] = null;
        }

        this.router.navigate(["/" + link], {
            queryParams: queryParams,
            queryParamsHandling: "merge"
        })
    }

    asIsOrder(a, b) {
        return Number.parseInt(a.sortOrder) > Number.parseInt(b.sortOrder) ? 1 : -1;
    }

    //determine if this top nav is active
    isActive(link): boolean {
        //check if this.router.url minus any http parameters matches the link
        let url = this.router.url.split("?")[0];
        return url == link;
    }
    isNavActive(link): boolean {
        return this.router.url.split("/")[1] == link.split("/")[0];
    }
    collapseMobileNav() {
        this.collapse.emit();
    }

    logout(): void {
        let role = this.authService.getRole();
        this.navService.clearNavs().subscribe(
            () => { // success callback
                this.authService.logout();

                //if role is not set, use regRole
                if (!role) {
                    switch (this.regRole) {
                        case "venue":
                            role = 97;
                            break;
                        case "performer":
                            role = 98;
                            break;
                    }
                }

                this.router.navigateByUrl("/auth/login?role=" + role);
            },
            (error) => {
                console.error("Error clearing the navs during logout:", error);
            }
        );
    }

}
