import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FieldBase } from 'src/app/base/components/fields/upload-base.component copy';
import { FormBaseComponent } from 'src/app/base/components/form-base.component';

@Component({
    selector: 'app-easysuite-form',
    templateUrl: './easysuite-form.component.html',
    styleUrls: ['./easysuite-form.component.css'],
})
export class EasysuiteFormComponent extends FormBaseComponent implements OnInit {

    constructor(public route: ActivatedRoute) { super(route) }

    ngOnInit(): void {
        super.ngOnInit();
    }

}
