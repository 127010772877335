import { Component, OnInit } from '@angular/core';
import { DropDownBaseComponent } from 'src/app/base/components/fields/dropdown-base.component';

@Component({
  selector: '[app-easysuite-dropdown]',
  templateUrl: './easysuite-dropdown.component.html',
  styleUrls: ['./easysuite-dropdown.component.css']
})
export class EasysuiteDropdownComponent extends DropDownBaseComponent implements OnInit {

  constructor() { super()}

  ngOnInit(): void {
  }

}
