<ng-template #rootItem let-item>
    <div class="mobileNav" [class.selected]='isActive(item.link, item.hasPage)' (click)="Tnavigate(item.link, item, item)">
        <span>
            <a (click)="navigate(item.link, item)">{{item.label | uppercase}}</a>
        </span>

        <!--Master Expand/ Collapse Arrow -->
        <span *ngIf="hasChildren(item); " (click)="expandOrCollapse(item)" class="">
            <mat-icon *ngIf="item.collapsed;else isItemCollapsed">arrow_right</mat-icon>
            <ng-template #isItemCollapsed>
                <mat-icon>arrow_drop_down</mat-icon>
            </ng-template>
        </span>
    </div>

    <ng-container *ngIf="hasChildren(item) && !item.collapsed">
        <div class="children">
            <ng-container *ngFor="let itemChild of item.children">
                <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: itemChild}"></ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-template>

<div class="mobilenav">
    <ng-scrollbar>
        <!-- gigmonster logo -->
        <div class="toprow">
            <img src='../assets/GiG Monster Logo [horizontal].svg' style='width:50%; height: 50%; padding-top: 10px; padding-bottom: 10px;' alt='Pedro' />
            <button mat-button (click)="collapseMobileNav()">
                <mat-icon style="color: white;">close</mat-icon>
            </button>
        </div>

        <!-- the actual list of navs -->
        <div class="navlist">
            <div>
                <div *ngFor="let item of data">
                    <ng-container (click)="navigate(item.link, item)">
                        <div class="navitem">
                            <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: item}"></ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- custom entries -->
            <div class="navitem">
                <a (click)="logout()">{{this.isRegMode ? "Back to Login" : "LOGOUT"}}</a>
            </div>

            <div class="navitem" *ngIf="this.isRegMode">
                <a style="cursor: pointer;" [class.selected]="isActive">{{this.regRole | uppercase}}</a>
            </div>
        </div>
    </ng-scrollbar>
</div>