import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup;

    constructor() { }

    ngOnInit(): void {
    }

    shouldBeChecked(): boolean {
        return this.formGroup.get(this.fieldKey).value == true;
    }

    click(checked) {
        this.formGroup.get(this.fieldKey).setValue(checked);
    }

}
