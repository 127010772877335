<link href="https://fonts.googleapis.com/css?family=Libre+Baskerville" rel="stylesheet">

<div class="root">
    <div class="container">

        <app-easysuite-header></app-easysuite-header>
        <app-easysuite-top-nav [psuedoLoginInfo]="this.loginPageInfo"
            *ngIf="(app.innerWidth >= 850)"></app-easysuite-top-nav>
        <app-easysuite-mobile-nav [psuedoLoginInfo]="this.loginPageInfo"
            *ngIf="(app.innerWidth < 850)"></app-easysuite-mobile-nav>

        <div class="content" style="padding:10px 0;background:#fff">
            <div class="row">
                <div class="col-md-3">
                    <app-easysuite-side-nav [psuedoLoginInfo]="this.loginPageInfo"></app-easysuite-side-nav>
                </div>
                <div class="col-md-9 change_padding">
                    <div class="border">
                        <div style="float:right">
                            <span>
                                <a href="?nav_id=&user_id=2521&org_id=7&page_id=330">Support</a>
                            </span>
                        </div>
                        <div *ngIf="this.page" id="main_page">
                            <table>
                                <tr>
                                    <td>
                                        <p class="title">{{this.page.Title}}</p>
                                    </td>
                                </tr>
                            </table>
                            <table id="page_description">
                                <tr>
                                    <td>
                                        <p id="main">
                                        <p id="page_des_short" style="font-weight:500;">
                                            {{this.page.Description}}
                                            <a *ngIf="!this.descriptionVisible" (click)="toggleDescription()"
                                                id="page_a_more">more...</a>
                                        </p>
                                        <p id="page_des_more" *ngIf="this.descriptionVisible">
                                            {{this.page.MoreDescription}}
                                        </p>
                                    </td>
                                </tr>
                            </table>
                            <div class="viewform">
                                <form>
                                    <app-easysuite-form *ngIf="this.page.Form" [form]="this.page.Form"
                                        [pageID]="this.pageID"
                                        (formSubmitted)="formValidAndSubmitted($event)"></app-easysuite-form>
                                </form>
                            </div>

                            <div *ngIf="page.Table">
                                <app-easysuite-table *ngIf="page.Table" [table]="page.Table"
                                    [reloadNotifier]="reloadTableSubject.asObservable()"
                                    (needsLoadingAnimation)="triggerLoading($event)"
                                    (pageAction)="handleActionResponse($event)"
                                    [pageID]="page.PageID"></app-easysuite-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>