<div [formGroup]="this.formGroup">
    <mat-form-field style="width: 100%" appearance="outline">
        <mat-label style="font-weight: 500;">{{field.label}}</mat-label>
        <input type="text" placeholder="{{field.label}}" matInput [formControlName]="this.fieldKey"
            [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>