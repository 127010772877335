<div>
    <div class='background-img'></div>
    <div class='header-container'>
        <div class='pedro' *ngIf='(app.innerWidth >= 850)'>
            <img src='../assets/GiG Monster Logo [horizontal].svg' style='width: 245px;' alt='Pedro' />
        </div>
        <ng-container *ngIf='(app.innerWidth < 850)'>
            <div class='pedroMobile'>
                <img src='../assets/GiG Monster Logo [horizontal].svg' style='width: 245px;' alt='Pedro' />
            </div>
        </ng-container>
        <div class="leftLeafOverlay">
            <!-- <h1 style="color: white;">orginfo</h1> -->
            <mat-form-field *ngIf="orginfo && !this.workflow.isInWorkFlow()">
                <mat-select (selectionChange)="onOrgChange($event)" [value]="selectedOrg">
                    <!-- ancestor -->
                    <mat-option *ngIf="orginfo.ancestor"
                        [value]="orginfo.ancestor.ancestor_org_id">{{orginfo.ancestor.org_name |
                        uppercase}}</mat-option>

                    <!-- current org -->
                    <mat-option [value]="this.auth.getOrg()">
                        <span *ngIf="orginfo.ancestor">&nbsp;&nbsp;&nbsp;&nbsp;</span>{{orginfo.orgName | uppercase}}
                    </mat-option>

                    <!-- show descendants as options with an indent -->
                    <ng-container *ngIf="orginfo.descendants">
                        <mat-option *ngFor="let org of orginfo.descendants" [value]="org.org_id">
                            <span *ngIf="orginfo.ancestor">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;{{org.org_name}}
                        </mat-option>
                    </ng-container>

                    <!-- siblings -->
                    <ng-container *ngIf="orginfo.siblings">
                        <mat-option *ngFor="let org of orginfo.siblings" [value]="org.org_id">
                            <span *ngIf="orginfo.ancestor">&nbsp;&nbsp;&nbsp;&nbsp;</span>{{org.org_name}}
                        </mat-option>
                    </ng-container>

                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
