import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { Field } from 'src/app/base/interfaces/field';

@Component({
    selector: 'app-timepicker',
    templateUrl: './timepicker.component.html',
    styleUrls: ['./timepicker.component.css']
})
export class TimepickerComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: FormGroup;

    //create an array of strings called options that list 15 minute increments from 12:00am to 11:45pm
    //this is used to populate the options in the timepicker
    options = Array.from({ length: 96 }, (_, i) => {
        let hour = Math.floor(i / 4);
        let minute: any = (i % 4) * 15;
        let ampm = hour < 12 ? 'AM' : 'PM';
        hour = hour == 0 ? 12 : hour > 12 ? hour - 12 : hour;
        minute = minute < 10 ? '0' + minute : minute;
        return hour + ':' + minute + ' ' + ampm;
    });

    filteredOptions: Observable<string[]>;

    constructor() { }

    ngOnInit(): void {
        this.filteredOptions = this.formGroup.get(this.fieldKey).valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );
    }

    private _filter(value: string): string[] {
        let filterValue = value.toLowerCase();

        //check only the 1st character and don't filter further

        //limit filterValue to 1st character
        if (filterValue.length > 1) filterValue = filterValue[0];
        return this.options.filter(option => option.toLowerCase().startsWith(filterValue));
    }

}