import { Component, OnInit } from '@angular/core';
import { CheckBoxBaseComponent } from 'src/app/base/components/fields/checkbox-base.component';

@Component({
  selector: '[app-easysuite-checkbox]',
  templateUrl: './easysuite-checkbox.component.html',
  styleUrls: ['./easysuite-checkbox.component.css']
})
export class EasysuiteCheckboxComponent extends CheckBoxBaseComponent implements OnInit {

  constructor() { super()}

  ngOnInit(): void {
  }

}
