import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class I3VService {

  constructor(private httpClient: HttpClient) { }

  tokenizeCreditCard(
    i3vUrl: string,
    accountID: string, 
    publicKey: string, 
    cardNumber: string, 
    exp: string, 
    cvv: string, 
    name: string): Observable<any> {
        //determine card type based on card number
        let cardType = 4; //visa
        if (cardNumber.toString().startsWith('4')) {
            cardType = 4; //visa
        } else if (cardNumber.toString().startsWith('5')) {
            cardType = 3; //mc
        } else if (cardNumber.toString().startsWith('6')) {
            cardType = 2; //discover
        } else if (cardNumber.toString().startsWith('3')) {
            cardType = 1; //amex
        }

        //format exp from (mm/yy) to separate for day and month
        let expArray = exp.split('/');
        let expMonth = expArray[0];
        let expYear = expArray[1];

        //split name into first and last name
        let nameArray = name.split(' ');
        let firstName = nameArray[0];
        let lastName = nameArray[1];

        if (i3vUrl == "https://dev.api.improvation.us/i3v/api/i3v") i3vUrl = "https://test.fuzepace.com";
        else if (i3vUrl == "https://api.improvation.us/i3v/api/i3v") i3vUrl = "https://api.fuzepace.com";

        return this.httpClient.post(i3vUrl + "/api/CreateCCToken", {
            Fuze_Account_ID: accountID,
            Fuze_PUBLIC_API_Key: publicKey,
            CardType: cardType,
            CardNumber: cardNumber,
            ExpirationMonth: expMonth,
            ExpirationYear: expYear,
            CVV: cvv,
            FirstName: firstName,
            LastName: lastName
        });
    }

}
