import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


Sentry.init({
    dsn: "https://f0033c2354ba41a4868392428222a2b9@events.improvation.us/6069788",
    release: "1.0.0",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.routingInstrumentation,
            tracingOrigins: ["localhost", "localhost:4200", "dev.api.improvation.us", "api.improvation.us"]
        }),
    ],
    tracesSampleRate: 1.0,
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(success => console.log(`Bootstrap success`))
    .catch(err => console.error(err));
