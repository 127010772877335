import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from '../../interfaces/field';

@Component({
  selector: 'app-textfield-base',
  template: ``,
  styles: []
})

export class TextFieldBaseComponent {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup

    constructor() {}

    getMask() {
        if (this.field.format) {
            if (this.field.format == "phone number") return "(000)-000-0000";
        }

        return "";
    }

    getIn() {
        return this.field.instructions;
    }
    
    getMaxLenght(){
        if(this.field.label){
            if(this.field.label == 'Account Number'){
                return 18;
            }

            else if(this.field.label == "Routing Number"){
                return 9;
            }
        }
        return 100
    }   
}
