import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SuperstructureEasysuiteComponent } from './components/easysuite-superstructure/easysuite-superstructure.component';
import { GlobalSharedModule } from '../global-shared/global-shared.module';
import { BaseModule } from '../base/base.module';
import { MaterialModule } from '../material/material.module';



import { EasysuiteHeaderComponent } from './components/easysuite-header/easysuite-header.component';
import { EasysuiteTopNavComponent } from './components/easysuite-top-nav/easysuite-top-nav.component';
import { EasysuiteSideNavComponent } from './components/easysuite-side-nav/easysuite-side-nav.component';
import { EasysuiteTableComponent } from './components/easysuite-table/easysuite-table.component';
import { EasysuiteFormComponent } from './components/easysuite-form/easysuite-form.component';
import { EasysuiteMobileNavComponent } from './components/easysuite-mobile-nav/easysuite-mobile-nav.component';
import { EasysuiteCheckboxComponent } from './components/easysuite-fields/easysuite-checkbox/easysuite-checkbox.component'; 
import { EasysuiteDatepickerComponent } from './components/easysuite-fields/easysuite-datepicker/easysuite-datepicker.component';
import { EasysuiteDropdownComponent } from './components/easysuite-fields/easysuite-dropdown/easysuite-dropdown.component';
import { EasysuiteEmailConfirmationComponent } from './components/easysuite-fields/easysuite-email-confirmation/easysuite-email-confirmation.component';
import { EasysuiteMultiCheckboxComponent } from './components/easysuite-fields/easysuite-multi-checkbox/easysuite-multi-checkbox.component';
import { EasysuiteMultilevelCheckboxComponent } from './components/easysuite-fields/easysuite-multilevel-checkbox/easysuite-multilevel-checkbox.component';
import { EasysuiteMultilevelCheckboxNonselectComponent } from './components/easysuite-fields/easysuite-multilevel-checkbox-nonselect/easysuite-multilevel-checkbox-nonselect.component';
import { EasysuitePasswordConfirmationComponent } from './components/easysuite-fields/easysuite-password-confirmation/easysuite-password-confirmation.component';
import { EasysuiteRadioComponent } from './components/easysuite-fields/easysuite-radio/easysuite-radio.component';
import { EasySuiteTextDollarsComponent } from './components/easysuite-fields/easysuite-text-dollars/easysuite-text-dollars.component';
import { EasysuiteTextareaComponent } from './components/easysuite-fields/easysuite-textarea/easysuite-textarea.component';
import { TextfieldComponent } from './components/easysuite-fields/easysuite-textfield/easysuite-textfield.component';
import { TimepickerComponent } from './components/easysuite-fields/easysuite-timepicker/easysuite-timepicker.component';
import { UploadComponent } from './components/easysuite-fields/easysuite-upload/easysuite-upload.component';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { EasysuiteLoginComponent } from './components/easysuite-login/easysuite-login.component';
import { EasysuitePasswordComponent } from './components/easysuite-fields/easysuite-password/easysuite-password.component';
@NgModule({
  declarations: [
    SuperstructureEasysuiteComponent,
    EasysuiteHeaderComponent,
    EasysuiteTopNavComponent,
    EasysuiteSideNavComponent,
    EasysuiteTableComponent,
    EasysuiteFormComponent,
    EasysuiteMobileNavComponent,
    EasysuiteLoginComponent,
    EasysuiteCheckboxComponent,
    EasysuiteDatepickerComponent,
    EasysuiteDropdownComponent,
    EasysuiteEmailConfirmationComponent,
    EasysuiteMultiCheckboxComponent,
    EasysuiteMultilevelCheckboxComponent,
    EasysuiteMultilevelCheckboxNonselectComponent,
    EasysuitePasswordConfirmationComponent,
    EasysuiteRadioComponent,
    EasySuiteTextDollarsComponent,
    TextfieldComponent,
    TimepickerComponent,
    UploadComponent,
    EasysuiteTextareaComponent,
    EasysuitePasswordComponent
  ],
  imports: [
    CommonModule,
    GlobalSharedModule,
    BaseModule,
    MaterialModule,
    MatFormFieldModule
  ],
  exports: [
    EasysuiteLoginComponent
  ]
})
export class EasysuiteModule { }
