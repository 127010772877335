import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ButtonService {

    public generateButtons(buttons): boolean {
        const keys = Object.keys(buttons);
        return keys.length > 1 || (keys.length === 1 && keys[0] !== '');
    }

}