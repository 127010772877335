<div class="App">
    <div class="top">
        <app-header></app-header>
        <mat-progress-bar [mode]="(this.pageLoading || this.loadTopAnimationOnly) ? 'indeterminate' : 'determinate'" value="0"></mat-progress-bar>
        <app-top-nav [isRegMode]="this.registerMode || this.forgotPassword || this.confirmPassword" [regRole]="this.registerRole"></app-top-nav>
    </div>
    <div class="side-and-content">
        <ng-container *ngIf='(app.innerWidth >= 850)'>
            <div class="sidenav">
                <app-sidenav [isRegMode]="this.registerMode || this.forgotPassword"></app-sidenav>
            </div>
        </ng-container>
        <ng-scrollbar class="scroll" #scrollable style="height: 100%; width: 100%;">
            <div class="content-container" [style.max-width.px]="app.innerWidth - 50">
                <ng-container *ngIf="errorText === '' && (page === undefined || page === null)">
                    <div class="loadingAnimation">
                        <ngx-skeleton-loader count="1" appearance="line" animation="progress-dark" [theme]="{'background-color': '#262a32', 'height': '50px', 'width': '20%'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="5" appearance="line" animation="progress-dark" [theme]="{'background-color': '#262a32'}"></ngx-skeleton-loader>
                    </div>
                </ng-container>
                <ng-container *ngIf="page !== undefined && page !== null">
                    <div class="titleAndSupport">
                        <!-- this is really hacky, but to keep spacing consistent, i added support and hid it -->
                        <h4 style="visibility: hidden;"><a>Support</a></h4>
                        <h3 class='page-header'>{{page.Title}}</h3>
                        <h4><a (click)="triggerSupportLink()">Support</a></h4>
                    </div>
                    <div *ngIf="page.SubmitMessage !== undefined" class="submitMessage">
                        <h2>
                            {{page.SubmitMessage}}
                            <ng-container *ngIf="showWorkflowNextStepMessage">
                                <br> <br>To continue with the next step of the registration process, <a class="workflowLink" (click)="markWorkflowStep();">click here.</a>
                            </ng-container>
                            <ng-container *ngIf="showWorkflowSkipMessage">
                                <br>
                                <a class="workflowLink" (click)="skipWorkflowStep();">Click here to skip for now.</a>
                            </ng-container>
                        </h2>
                    </div>

                    <ng-container *ngIf="page.TopButtons">
                        <div *ngIf="generateButtons(page.TopButtons)" class="topButtons">
                          <ng-container *ngFor="let button of page.TopButtons | keyvalue">
                            <button
                              style="margin-right: 20px;"
                              mat-raised-button
                              color="primary"
                              *ngIf="button.key !== ''"
                              (click)="handleButtonLink(button.value.Type, button.key, button.value.Link)"
                            >
                              {{ button.key }}
                            </button>
                          </ng-container>
                        </div>
                      </ng-container>
                    
                    <div class="description">
                        <p style="margin: 0px;">
                            <span id="descriptionText" *ngIf= "getDescription()" [innerHTML]="getDescription()" hrefToRouterLink></span>
                            <a *ngIf="page.MoreDescription !== undefined && page.MoreDescription !== null && page.MoreDescription !== ''" (click)="toggleDescription()" style="background-color: transparent; border: none; color: orange; cursor: pointer;">
                                {{!descriptionVisible ? 'Instructions...' : ''}}</a>
                        </p>
                        <p class="moreDescription" *ngIf="descriptionVisible" [innerHTML]="page.MoreDescription"></p>
                    </div>

                      <ng-container *ngIf="page.Buttons">
                        <div *ngIf="generateButtons(page.Buttons)" class="buttons">
                          <ng-container *ngFor="let button of page.Buttons | keyvalue">
                            <button
                              style="margin-right: 20px;"
                              mat-raised-button
                              color="primary"
                              *ngIf="button.key !== ''"
                              (click)="handleButtonLink(button.value.Type, button.key, button.value.Link)"
                            >
                              {{ button.key }}
                            </button>
                          </ng-container>
                        </div>
                      </ng-container>

                    <app-form *ngIf="page.Form" [form]="page.Form" [pageID]="this.pageID" (formSubmitted)="formValidAndSubmitted($event)"></app-form>
                    <div *ngIf="page.Table" class="test mat-elevation-z8">
                        <app-table style="height: 300px" *ngIf="page.Table" [table]="page.Table" [reloadNotifier]="reloadTableSubject.asObservable()" (needsLoadingAnimation)="triggerLoading($event)" (pageAction)="handleActionResponse($event)" [pageID]="page.PageID" [pageProvidedTableData]="page.TableData"
                            [reloadWithDataNotifier]="this.reloadTableWithDataSubject.asObservable()"></app-table>
                    </div>
                </ng-container>
                <ng-container *ngIf="errorText !== ''">
                    <div class="warningInfo">
                        <!-- display warning.svg with white color -->
                        <img id="warningicon" src="assets/warning2.svg" alt="warning" style="width: 100%; height: 200px;">
                        <h3>This page failed to load properly.</h3>
                        <h4>We apologize for the inconvienence.</h4>
                        <p>Our team has been notified. If you'd like to report additional information, click this link.
                        </p>
                    </div>
                </ng-container>
            </div>
        </ng-scrollbar>
    </div>
</div>