import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';
import { TextfieldService } from 'src/app/base/services/textfield/textfield.service';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup;

    constructor(private TextfieldService: TextfieldService) { }

    /**
     * Comparator function for sorting key-value pairs based on the 'order' property.
     *
     * This function is designed to be used as a comparator for sorting key-value pairs,
     * where each value has an 'order' property. The sorting is based on numerical comparison
     * of the 'order' properties.
     *
     * @param a - The first key-value pair.
     * @param b - The second key-value pair.
     * @returns A negative number if a should come before b, a positive number if b should come
     *          before a, and 0 if their 'order' properties are equal or undefined.
     */
    originalOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
      
        if (a.value.order !== undefined && b.value.order !== undefined) {
          return a.value.order - b.value.order;
        }
        return 0;
    }

    getIn() {
        return this.field.instructions;
    }

    onSelectionChange(event, choices) {
        //slot dropdown
        if(event.source.ngControl.name == '734'){
            this.TextfieldService.setSharedData({fieldId: '734', price: choices[event.value].price});
        }
    }
    
    ngAfterViewInit() {
        //if the dropdown has only one value, set it to that value.
        if (Object.keys(this.field.choices).length === 1) {
            const firstOptionValue = Object.keys(this.field.choices)[0];
            this.formGroup.get(this.fieldKey).setValue(firstOptionValue);
        }
    }
}
