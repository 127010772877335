import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MultiLevelCheckBoxBaseComponent } from 'src/app/base/components/fields/mutlilevel-checkbox-base.component';
import { Field } from 'src/app/base/interfaces/field';

@Component({
    selector: 'app-easysuite-multilevel-checkbox',
    templateUrl: './easysuite-multilevel-checkbox.component.html',
    styleUrls: ['./easysuite-multilevel-checkbox.component.css']
})
export class EasysuiteMultilevelCheckboxComponent extends MultiLevelCheckBoxBaseComponent implements OnInit {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup;

    constructor() { super() }//d

    ngOnInit(): void {
    }

}
